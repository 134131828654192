import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {JobOpening} from "./job-opening";
import {HttpClient} from "@angular/common/http";
import {JobOpeningCard} from "../../card/job-opening-card";
import {environment} from "../../../environments/environment";
import { map } from 'rxjs/operators';

@Injectable()
export class JobOpeningService {

    cachedOpenings:Array<JobOpeningCard>;
    constructor(private http: HttpClient) {

    }

    getTechJobs(): Observable<Array<JobOpeningCard>> {
      return this.http.get(environment.api+"/jobs/tech").pipe(map((res: any) => {
        var items = res.data;
              var openings:Array<JobOpeningCard> = [];
              for(var i:number=0; i<items.length; i++){
                  var card:JobOpeningCard = new JobOpeningCard(items[i].subject,items[i].resume,items[i].unid,items[i].urlId,items[i].company);
                  card.pageLink = "/jobb/"+card.unid;
                  openings.push(card);
              }
              this.cachedOpenings=openings;
              return openings;
      }));
    }

    getJobOpenings():Observable<Array<JobOpeningCard>>{
        if(this.cachedOpenings){
          return new Observable<Array<JobOpeningCard>>(observer=>{
            observer.next(this.cachedOpenings);
            observer.complete();
          });
        }else{
          return this.http.get(environment.api+"/jobs/").pipe(map((res: any) => {
            var items = res.data;
              var openings:Array<JobOpeningCard> = [];
              for(var i:number=0; i<items.length; i++){
                  var card:JobOpeningCard = new JobOpeningCard(items[i].subject,items[i].resume,items[i].unid,items[i].urlId,items[i].company);
                  card.pageLink = "/jobb/"+card.unid;
                  openings.push(card);
              }
              this.cachedOpenings=openings;
              return openings;
          }));
        }
    }

    getJob(unid:string):Observable<JobOpening>{
        return this.http.get(environment.api+"/jobs/"+unid).pipe(map((res:any) => res.data as JobOpening));
    }

}
