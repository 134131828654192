
<ul *ngIf="useInSidebar" class="events-container">
  <li *ngFor="let event of filteredEvents">
    <a class="event" (click)="changeRoute(event.unid)">
      <span class="event-date">{{event.StartDate|date:"ddMMM HH:mm"}}</span>
      <span class="event-name">{{event.Subject}}</span>
    </a>
  </li>
</ul>

<div *ngIf="!useInSidebar">
  <app-common-header *ngIf="imgSrc"></app-common-header>
  <app-shortcuts [isCollapsed]="true" [isPage]="true"></app-shortcuts>

  <div class="page-container page">
    <div class="row banner">
      <div class="small-2 medium-6 columns">
        <span class="simple icon-calendar"></span>
        <span class="hide-for-small-only text"> Arrangementer</span>
      </div>
      <div *ngIf="!removeFilter" class="small-6 medium-3 columns">
        <form action="" class="navigation-search">
          <label class="search-box-label" for="kaskjer-search-box">Hurtigsøk</label>
          <input class="search-box-input" type="text" id="kaskjer-search-box"
                 (change)="search($event)">
        </form>
      </div>
      <div *ngIf="!removeFilter" class="small-4 medium-3 columns">
        <a class="button login-button" (click)="login()">Opprett arrangement</a>
      </div>
    </div>

    <div  *ngIf="!removeFilter" class="row filters">
      <div class="small-3 medium-2 columns">
        <select (change)="categoryChange($event)">
          <option selected disabled>Kategorier</option>
          <option *ngFor="let cat of categories" value="{{cat}}" label="{{cat}}">{{cat}}</option>
          <option value="all" label="Alle">Alle</option>
        </select>
      </div>
      <div class="small-4 medium-2 columns">
        <select (change)="timeIntervalChange($event)">
          <option selected disabled>Når?</option>
          <option value="today" label="I dag">I Dag</option>
          <option value="week" label="Neste 7 dager">Neste 7 dager</option>
          <option value="month" label="Neste 31 dager">Neste 31 dager</option>
          <option value="all" label="Når som helst">Når som helst</option>
        </select>
      </div>
      <div class="small-4 medium-2 columns">
        <select (change)="locationChange($event)">
          <option selected disabled>Hvor?</option>
          <option *ngFor="let loc of locations" value="{{loc}}" label="{{loc}}">{{loc}}</option>
          <option value="all" label="Hvor som helst">Hvor som helst</option>
        </select>
      </div>
      <div class="small-4 medium-2 columns"></div>
    </div>
    <div class="row titles">
      <div class="small-7 medium-4 columns" (click)="selectColumn(0);sortEvents('Subject')" >Arrangement</div>
      <div class="small-5 medium-3 columns" (click)="selectColumn(1);sortEvents('StartDate')" >Tidspunkt</div>
      <div class="hide-for-small-only medium-3 columns" (click)="selectColumn(2);sortEvents('Location')">Sted</div>
      <div *ngIf="!removeFilter" class="hide-for-small-only medium-2 columns" (click)="selectColumn(3);sortEvents('Company')">Arrangør</div>
    </div>

    <div (click)="redirectTo('https://kaskjer.rauma.no/kaskjer/'+event.unid)" class="row kaskjer-entries" *ngFor="let event of filteredEvents; let index=index" [class.color-water2]="index%2==0" [class.color-mountain2]="index% 2==1">
      <div class="small-7 medium-4 columns">{{event.Subject}}</div>
      <div class="small-5 medium-3 columns">{{event.StartDate|date:"ddMMM HH:mm"}}</div>
      <div class="hide-for-small-only medium-3 columns">{{event.Location}}</div>
      <div *ngIf="!removeFilter" class="hide-for-small-only medium-2 columns">{{event.Company}}</div>
    </div>
  </div>
</div>
