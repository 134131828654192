/**
 * When getting list items from the server, they need to be formatted with at least the properites of a {@link Card}
 */
export class Card {
    unid:string; // unid to the page that should display the full info of the content of the card
    extraHeader?:string; //Nordveggen wanted to have a small text above the subject, thus added this.
    Subject:string;
    Resume:string;
    pageLink:string;
    urlId:string;
    externalUrl?:string;
    showDate?: Date|number;
    created?: any;
    subject: any;
    resume?: any;

    constructor(title:string,summary:string,unid:string,urlId:string,showDate:Date|number){
        this.Subject=title;
        this.Resume=summary;
        this.unid=unid;
        this.urlId=urlId;
        this.showDate=showDate;
    }
}
