import { Injectable } from '@angular/core';

declare let ga:Function;

@Injectable()
export class GoogleService {

  constructor() {

  }

  sendPageView(url:string){
    try{
      if(!ga){
        setTimeout(()=>{
          this.sendPageView(url);
        },500);
      }else{
        console.log("send pageview",url);
        ga('set','page',url);
        ga('send','pageview');
      }
    }catch(e){
      if(e instanceof ReferenceError){
        console.warn("ga is not defined yet, trying to send new pageView in 500ms");
      }
      setTimeout(()=>{
        this.sendPageView(url);
      },500);
    }

  }

}
