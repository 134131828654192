import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.css']
})
export class ShortcutsComponent implements OnInit {

  @Input() isCollapsed:boolean=false;
  @Input() isPage:boolean=false;
  constructor() { }

  ngOnInit() {
  }

  closeNavigationMenu(event){
    var bodyDom:any = document.getElementsByTagName("body")[0];
    bodyDom.className="";
  }

}
