import {Component, OnInit, Input, ViewEncapsulation, OnDestroy} from '@angular/core';
import {NewsEntry} from "../news-entry/news-entry";
import {NewsService} from "../news-entry/news.service";
import {PageService} from "../page/page.service";
import {Page} from "../page/page";
import {Title} from "@angular/platform-browser";
import {AppUtilsService} from "../app-utils.service";
import {Subscription} from "rxjs/Subscription";

@Component({
  selector: 'app-kalender',
  templateUrl: './kalender.component.html',
  styleUrls: ['./kalender.component.css'],
  encapsulation: ViewEncapsulation.None // used in order to apply style to children as well
})
export class KalenderComponent implements OnInit, OnDestroy  {

  newsEntries:Array<NewsEntry>;
  urlId:string="kalender";
  page:Page;
  MEDIA_MOBILE=AppUtilsService.MEDIA_MOBILE;
  MEDIA_DESKTOP=AppUtilsService.MEDIA_DESKTOP;
  media:string=AppUtilsService.MEDIA_DESKTOP;
  mediaSubscription:Subscription;
  constructor(private newsService:NewsService, private pageService:PageService, private titleService:Title,
              public apputilService:AppUtilsService) {
    this.mediaSubscription = this.apputilService.mediaSubject.subscribe(media=>{
      this.media=media;
      this.onMediaChanged(media);
    });
  }

  ngOnInit() {
    this.media = this.apputilService.getMedia();
    this.onMediaChanged(this.media);
    this.titleService.setTitle("Nordveggen");
    this.newsService.getNews(3).subscribe(news=>{
      this.newsEntries=news
    });
    this.pageService.getPage(this.urlId,"").subscribe(page=>{
      this.page=page;
    })
  }
  ngOnDestroy(){
    this.mediaSubscription.unsubscribe();
  }

  onMediaChanged(media:string):void{
    this.media=media;
  }
}
