import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Page} from "./page";
import {Observable} from "rxjs";
import {Card} from "../card/card";
import {NewsLetterPage} from "../news-letter-page";
import {environment} from "../../environments/environment";
import { map } from 'rxjs/operators';


@Injectable()
export class PageService {

  constructor(private http:HttpClient) {

  }

  getPage(urlId:string,unid:string):Observable<Page>{
    var url:string = environment.api + "/web/id/" +urlId;
    if(unid)url+="?unid="+unid;
    var bodyDom:any = document.getElementsByTagName("body")[0];
    bodyDom.setAttribute("page-status","page-loading");
    return this.http.get(url).pipe(map((res:any) =>{
      var page:Page = res.data as Page;
      page.ImageData = page.ImageData||page.images;
      page.Status=page.Status||page.status;
      if(page.Status!="Publiser"){
        page = <Page>{subject:"Ikke publisert enda"};
      }
      bodyDom.setAttribute("page-status","page-loaded");
      return page;
    }));
  }

  getNewsletterPage(urlId:string):Observable<NewsLetterPage>{
    var url:string = environment.apiIbricks + "/web/"+urlId+"?orgId=E99C4";
    return this.http.get(url).pipe(map((res:any) =>{
      return res.data as NewsLetterPage;
    }));
  }

  getPagesByTag(tag:string):Observable<Array<Card>>{
    return this.http.get(environment.api+"/web/pages/tag/"+tag).pipe(map((res:any) =>{
      return res.data as Array<Card>;
    }));
  }

  getPagesBySeachTerm(searchTerm:string):Observable<Array<Card>>{
    return this.http.post(environment.api+"/search/",{searchTerm:searchTerm}).pipe(map((res:any) =>{
      return res.data as Array<Card>;
    }));
  }
}


