import { Component, OnInit } from '@angular/core';
import {Card} from "../card/card";
import {NewsService} from "../news-entry/news.service";
import {NavigationEnd, Router} from "@angular/router";
import {GoogleService} from "../google.service";

@Component({
  selector: 'app-naturglederposten',
  templateUrl: './naturglederposten.component.html',
  styleUrls: ['./naturglederposten.component.css']
})
export class NaturglederpostenComponent implements OnInit {

  allPosts:Array<Card> = [];
  isLoading:boolean = true;
  constructor(private newsService:NewsService) {

  }

  ngOnInit() {
    this.newsService.getAllNewsletters().subscribe(newsletters=>{
      this.isLoading=false;
      for(var ne of newsletters){
        var newsCard:Card = new Card(ne.subject,ne.resume,ne.unid,ne.urlId, ne.showDate);
        newsCard.pageLink="/nyhetsbrev/"+ne.urlId;
        this.allPosts.push(newsCard);
      }
    });
  }

}
