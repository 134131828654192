import {Component, OnInit, Input, AfterViewInit, OnDestroy} from '@angular/core';
import {AppUtilsService} from "../app-utils.service";
import {ImgData} from "../img-data";
import * as StackBlur from 'stackblur-canvas';
import {Observable} from "rxjs/Observable";
import {Subscription} from "rxjs/Subscription";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.css']
})
export class CommonHeaderComponent implements OnInit, OnDestroy, AfterViewInit{

  // @HostBinding('class.page-header-ready') colorMountain:boolean = false;
  @Input() imageData:Array<ImgData>;
  @Input() blur:boolean = false;
  @Input() blurRadius:number=5;
  @Input() title:string;
  @Input() description:string;
  @Input() useLessHeight:boolean;
  imgSrc:string;
  media:string;
  pageHeaderReady:boolean = false;
  imgSize:string="large";
  mediaSubscription:Subscription;
  constructor(private appUtils:AppUtilsService) {
    this.mediaSubscription = this.appUtils.mediaSubject.subscribe(media=>{
      this.media = media;
      this.onMediaChanged(media);
    });
  }

  ngOnInit() {
    this.media=this.appUtils.getMedia();
    this.onMediaChanged(this.media);
    this.calcImgSize(this.appUtils.getMedia());
    this.generateImgSrcFromImageDate();
  }
  ngAfterViewInit(){
    this.loadCanvasImage();
  }
  ngOnDestroy(){
    this.mediaSubscription.unsubscribe();
  }
  loadCanvasImage():void{
    var canvas:any = document.getElementById('header-canvas');
    if(!canvas)return;
    var myImage = new Image();
    myImage.crossOrigin = 'Anonymous';
    var thisComponent = this;
    var blur:boolean = this.blur;
    myImage.onload = function(){
      var imgWidth = myImage.naturalWidth;
      var imgHeight = myImage.naturalHeight;
      var imgRatio = imgWidth/ imgHeight;
      if(blur){
        StackBlur.image(myImage, canvas, thisComponent.blurRadius, false);
      }else{
        StackBlur.image(myImage, canvas, 0, false);
      }
      thisComponent.setPageHeaderReady(true);
      thisComponent.resizeCanvas(imgRatio);
      window.addEventListener('resize', (event:any) => {
        thisComponent.resizeCanvas(imgRatio);
      });
    };
    myImage.src=this.imgSrc;
  }

  resizeCanvas(imgRatio):void{
    var window_w:number = window.innerWidth;
    var window_h:number = window.innerHeight;
    if(this.useLessHeight){
      window_h = window.innerHeight - window.innerHeight*0.25;
    }
    var window_ratio = window_w / window_h;
    var canvas:any = document.getElementById("header-canvas");
    if(canvas){
      if (imgRatio > window_ratio) {
        canvas.style.height = window_h+"px";
        canvas.style.width = "auto";
        var canvas_w:number = parseInt(canvas.style.width);
        canvas.style.left=""+((canvas_w - window_w)/2) * -1;
        canvas.style.top="auto";
      }else if (imgRatio < window_ratio) {
        canvas.style.height = "auto";
        canvas.style.width = window_w+"px";
        var canvas_h:number = parseInt(canvas.style.height);
        canvas.style.left="auto";
        canvas.style.top=""+((canvas_h - window_h)/2) * -1;
      }
    }
  }

  generateImgSrcFromImageDate():void{
    if(this.media == AppUtilsService.MEDIA_DESKTOP){
      this.setDefaultImage();
    }else if(this.media == AppUtilsService.MEDIA_MOBILE){
      this.setMobileImage();
    }else{
      this.setDefaultImage();
    }
  }

  setDefaultImage(){
    if(this.imageData && this.imageData.length>0){
      var imgData = this.imageData[0];
      this.imgSrc = "/bilder/" + imgData.urlId +"/$File/"+this.imgSize+"."+imgData.fileExtension;
    }
  }
  setMobileImage(){
    if(this.hasMobileImage(this.imageData[0])){
      var imgData = this.getCroppedVersion(this.imageData[0],"mobile");
      this.imgSrc = "/bilder/" + imgData.urlId +"/$File/medium.png";
    }else{
      this.setDefaultImage();
    }
  }
  getCroppedVersion(imageData:ImgData,cropId:string):ImgData{
    for(var i = 0; i<imageData.responseImages.length; i++){
      if(imageData.responseImages[i].cropId==cropId){
        return imageData.responseImages[i];
      }
    }
  }
  hasMobileImage(imageData:ImgData):boolean{
    return (imageData && imageData.responseImages && imageData.responseImages.length>0);
  }
  onMediaChanged(media:string){
    this.media = media;
    this.calcImgSize(media);
    this.generateImgSrcFromImageDate();
    this.loadCanvasImage();
  }

  setPageHeaderReady(ready:boolean):void{
    this.pageHeaderReady = ready;
  }

  private calcImgSize(media:string){
    if(media===AppUtilsService.MEDIA_DESKTOP){
      this.imgSize="xxlarge";
    }else if(media===AppUtilsService.MEDIA_TABLET){
      this.imgSize="xlarge";
    }

  }
}
