<app-shortcuts *ngIf="useShortcuts" [isPage]="true" [isCollapsed]="true" ></app-shortcuts>

<app-common-header *ngIf="page && page.ImageData && page.ImageData.length>0" [imageData]="page.ImageData" [blur]="blurImage" [title]="blurImage?page.subject:''" [useLessHeight]="useLessHeight"></app-common-header>

<div class="page-container page" [class.no-image]="noImage">
  <div class="page-container-inner"
       [class.title-background]="!blurImage">
    <div class="page-container-wrapper">
      <main class="page-container-page" *ngIf="page">
        <h1 *ngIf="!blurImage">{{page.subject}}</h1>
        <ng-content select=".page-extra"></ng-content> <!-- transclude(put) the element with the class page-body in here.-->
        <div *ngIf="page.body||page.Body" [innerHTML]="page.body||page.Body|sanitizeHtml"></div>
        <p></p>
        <ng-content select=".page-extra-after-body"></ng-content>
        <div *ngIf="page.links && page.links.length>0">
          <h3>Koblinger:</h3>
          <label class="attachment" *ngFor="let link of page.links">
            <a [href]="link.url" target="_blank">{{link.text}}</a>
          </label>
        </div>
        <div *ngIf="hasAttachments()">
          <h3>Vedlegg:</h3>
          <label class="attachment" *ngFor="let attachment of page.attachments">
            <a [href]="environment.vedlegg+'/'+attachment.unid+'/$File/'+attachment.filename" target="_blank">{{attachment.subject||attachment.filename}}</a>
          </label>
        </div>
        <div *ngIf="pageDate" class="entry-date">Sist endret: {{pageDate}}</div>
      </main>
    </div>
  </div>
</div>
