// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  api: "https://api.ibricks.no/v1/E99C4",
  apiIbricks: "https://api.ibricks.no/v1",
  apiKaskjer: "https://api.ibricks.no/rauma/kaskjer.nsf/RestAPI.xsp",
  bilder: "https://api.ibricks.no/bilder/E99C4",
  ksbilder: "https://api.ibricks.no/nordveggen/web.nsf/imgbyid",
  vedlegg: "https://api.ibricks.no/nordveggen/web2.nsf"
};
