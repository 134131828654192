<!-- <div class="page-nub" page-nub></div> -->
<app-common-header *ngIf="page" [imageData]="page.ImageData" [blur]="true" [title]="page.subject"
  [description]="page.Resume">
</app-common-header>

<div class="page-container">
  <div class="page-container-wrapper">
    <div>
      <!-- <h2>Arrangement</h2> -->
      <main class="page-container-page" *ngIf="page">
        <div *ngIf="page.body||page.Body" [innerHTML]="page.body||page.Body"></div>
      </main>
      <ka-skjer [type]="'Nordveggen'" [useInSidebar]="false" [removeFilter]="true"></ka-skjer>
    </div>
  </div>
</div>