import { Component, OnInit, HostListener } from '@angular/core';
import { Card } from "../card/card";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { IsiMiscService } from "../isi-misc.service";
import { GoogleService } from "../google.service";
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

  allPosts: Array<Card> = [];
  isLoading: boolean = true;
  unid: string;
  job: any;
  companies: Array<any>;
  imgSrc: string;
  filterForm: FormGroup;
  filteredCompanies: Array<any> = []

  constructor(private http: HttpClient, private activeRoute: ActivatedRoute) {
    this.unid = activeRoute.snapshot.params["unid"];
    this.filterForm = new FormGroup({
      'filterCtrl': new FormControl(''),
    });
  }

  ngOnInit() {
    if (this.unid) {
      this.getJob(this.unid);
    } else {
      this.getAllJobs();
    }

    this.imgSrc = "../../assets/xlarge.jpg";
    this.onChanges();
  }

  onChanges(): void {
    this.filterForm.get('filterCtrl').valueChanges.subscribe(val => {
      console.log(val);
      if (val && val.length > 0) {
        this.filteredCompanies = [];
        this.companies.forEach(data => {
          const name = data.name.toLocaleLowerCase();
          if (name.indexOf(val) != -1) this.filteredCompanies.push(data);
        })
      } else {
        this.filteredCompanies = this.companies;
      }
      this.createJobCards(this.filteredCompanies);

    });
  }

  getJob(unid: string) {
    this.http.get("api/jobs/companies/" + unid).subscribe((res: any) => {
      this.job = res.data;
      this.isLoading = false;
    });
  }
  getAllJobs() {
    this.http.get("api/jobs/companies").subscribe((res: any) => {

      this.companies = res.data;

      this.isLoading = false;
      this.createJobCards(this.companies);
    });
  }

  createJobCards(companies: Array<any>) {
    this.allPosts = [];
    /*  companies = companies.sort(function (a, b) {
       if (!a || !b) return -2;
       else if (!a.name || !b.name) return -3;
       else if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
       else if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
       return 0;
     }); */
    const collator = new Intl.Collator('no');
    companies = companies.sort((a, b) => {
      return collator.compare(a.name, b.name)
    });
    var shuffledSortedCompanies = companies;
    for (var c of shuffledSortedCompanies) {
      if (c && c.name) {
        var compCard: Card = new Card(c.name, "", c.unid, "", null);
        compCard.externalUrl = c.url;
        this.allPosts.push(compCard);
      }
    }
  }

}
