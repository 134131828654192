import { Pipe, PipeTransform } from '@angular/core';
import {KaSkjerEvent} from "../ka-skjer/ka-skjer-event";
@Pipe({name: 'kaSkjer'})
export class KaSkjerPipe implements PipeTransform {
  /**
   *
   * @param values
   * @param filterType "sort" or "filter". Will use different methods based on type.
   * sort will change the order of all elements. Filter will remove elements that does not match with the value of a field
   *
   * @param fieldName
   * @param inverse
   * @return {Array<KaSkjerEvent>}
   */
  public static TYPE_FILTER = "filter";
  public static TYPE_SORT = "sort";
  public static TYPE_DATE = "date";
  public static TYPE_FREE_SEARCH = "free";
  transform(values:Array<KaSkjerEvent>, filterType:string, fieldName:string, inverse?:boolean, fieldValue?:string): Array<KaSkjerEvent> {
    if(filterType==KaSkjerPipe.TYPE_SORT)
      return this.sortOnly(values,fieldName,inverse);
    else if(filterType==KaSkjerPipe.TYPE_FILTER){
      return this.filterArray(values,fieldName,fieldValue);
    }else if(filterType==KaSkjerPipe.TYPE_DATE){
      return  this.filterDate(values,fieldName,fieldValue);
    }else if(filterType==KaSkjerPipe.TYPE_FREE_SEARCH){
      return this.freeSearch(values,fieldValue);
    }
  }
  filterDate(values:Array<KaSkjerEvent>,fieldName:string,fieldValue:string){
    var now:Date = new Date();
    if(fieldValue == "all"){
      return values;
    }
    else if(fieldValue=="today"){
      now.setDate(now.getDate() + 1);
    }
    else if(fieldValue=="week"){
      now.setDate(now.getDate() + 7);
    }else if(fieldValue=="month"){
      now.setDate(now.getDate() + 31);
    }
    return values.filter(function(value){
      return (now.getTime()-value[fieldName] >= 0);
    });

  }
  filterArray(values:Array<KaSkjerEvent>,fieldName:string, fieldValue:string):Array<KaSkjerEvent>{
    if(fieldValue == "all") return values;
    var filteredArray:Array<KaSkjerEvent> = values.filter(
      function(value){
        // return true;
        if(Array.isArray(value[fieldName])){
          value[fieldName].filter(function(cat){
            if(cat==fieldValue){
              return true;
            }
          });
        }
        return value[fieldName]==fieldValue;
      });
    return filteredArray;
  }
  sortOnly(values:Array<KaSkjerEvent>,fieldName:string,inverse:boolean):Array<KaSkjerEvent>{
    var sortedArray:Array<KaSkjerEvent>= values.sort((a,b)=>{
      if(a[fieldName]>b[fieldName]){
        if(inverse) return -1;
        return 1;
      }
      if(a[fieldName]<b[fieldName]){
        if(inverse) return 1;
        return -1;
      }
      return 0;
    });
    return sortedArray;
  }
  freeSearch(values:Array<KaSkjerEvent>,fieldValue:string){
    return values.filter(function(value:KaSkjerEvent){
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if(Array.isArray(value[key])){
            for(var i = 0; i<value[key].length; i++){
              if(value[key][i].toString().toLowerCase().match(fieldValue.toLowerCase()))return true;
            }
          }
          else if(value[key].toString().toLowerCase().match(fieldValue.toLowerCase()))return true;
        }
      }
    });
  }
}
