import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Card } from '../card/card';
import { JobOpeningService } from '../page/job-opening/job-opening.service';
import { Page } from '../page/page';
import { PageService } from '../page/page.service';

@Component({
  selector: 'app-jobbirauma',
  templateUrl: './jobbirauma.component.html',
  styleUrls: ['./jobbirauma.component.css']
})
export class JobbiraumaComponent implements OnInit {
  jobOpenings: Array<Card>;
  isLoaded:boolean=false;
  page: Page;
  urlId: string = "jobbiraumakampanje";
  constructor(public jobOpeningService: JobOpeningService, public pageService: PageService, public activatedRoute: ActivatedRoute,
    public titleService: Title, public meta: Meta) {
  }

  ngOnInit() {
    this.titleService.setTitle("Jobb i Rauma – Nordveggen");
    if (!this.page) {
      this.pageService.getPage(this.urlId, "").subscribe(page => {
        this.page = page;
        this.isLoaded = true;
        this.addMeta();
      });
    }
    if (!this.jobOpenings || this.jobOpenings.length <= 0) {
      this.getJobOpenings();
    }
  }

  getJobOpenings(): void {
    this.jobOpenings = [];
    this.jobOpeningService.getTechJobs().subscribe((openings) => {
      for (var i: number = 0; i < openings.length; i++) {
        if (openings[i] && openings[i].company) {
          openings[i].extraHeader = openings[i].company + " søker:";
        }
        this.jobOpenings.push(openings[i]);
      }
    });
  }

  addMeta() {        
    if (this.page) {
      console.log('add meta', this.page);
      var imageData = this.page.ImageData || this.page.images;
      const imgUrl = 'https://www.nordveggen.no/'+imageData[0].src;
      
      this.meta.updateTag({ 'property': 'og:image', 'content':  imgUrl});

      this.meta.updateTag({ 'property': 'og:title', 'content' : this.page.subject });
      this.meta.updateTag({ 'property': 'og:description', 'content' : this.page.resume });
    }
  }



}
