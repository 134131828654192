<div class="page-container page no-image" >
    <h1>Nordveggen nyhetsbrev</h1>
    <p>Hold deg oppdatert på det som skjer i næringslivet</p>
    <div class="row" *ngIf="!subscribed">
      <div class="small-2 columns">&nbsp;</div>
      <div class="small-8 columns">
        <form #optinoutForm="ngForm" [formGroup]="formGroup" (submit)="onSubmit(formGroup.value)">
          <input type="text" placeholder="E-post" formControlName="internetAddress" required>
          <div>
            <h4>Valg for nyhetsbrev</h4>
            <input type="checkbox" formControlName="naturgledePosten" name="Naturgledeposten" value="valg1">
            Naturgledeposten - nytt fra næringslivet i Rauma <br>
            <input type="checkbox" formControlName="levendeLesja" name="LevandeLesja" value="valg2">
            Nytt fra Levande Lesja
            <br><br>
            <input type="checkbox" formControlName="consent" name="consent" value="consemt" required>
            Jeg samtykker til å motta Nordveggens nyhetsbrev.
          </div>
          <br>
          <div class="button-group">
            <button class="button" type="submit" [disabled]="formGroup.invalid || !isValid">
              <span>
                {{subscribeButtonTitle}}
              </span>
            </button>
          </div>
        </form>
      </div>
      <div class="small-2 columns">&nbsp;</div>
    </div>
    <div *ngIf="subscribed" class="row optin-subscribed">
      <div class="small-12 columns">
        Takk for at du vil abbonnere på vårt nyhetsbrev. Du vil snart få en epost for å bekrefte påmeldingen til nyhetsbrevet vårt 
      </div>
    </div>
    <div *ngIf="allPosts" class="page-container">
      <app-cards [cards]="allPosts"  [class.use-pointer]="allPosts.pageLink"></app-cards>
    </div>
    <div *ngIf="!allPosts|| isLoading" class="page-container page">
      <h2>Vennligst vent mens naturgledepostene lastes. Dette kan ta litt tid.</h2>
    </div>
    
</div>