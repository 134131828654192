import { BrowserModule,Meta,Title } from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeNorwegian from '@angular/common/locales/nb';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule }          from '@angular/forms';
import { DynamicFormComponent }         from './dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './dynamic-form-question/dynamic-form-question.component';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from './page/page-not-found/page-not-found.component';
import { KaSkjerService } from "./ka-skjer/ka-skjer.service";
import { KaSkjerComponent } from './ka-skjer/ka-skjer.component';
import { HomeComponent } from './home/home.component';
import { PageComponent } from './page/page.component';
import { CardComponent } from './card/card.component';
import { JobOpeningComponent } from "./page/job-opening/job-opening.component";
import { JobOpeningsComponent } from './job-openings/job-openings.component';
import {JobOpeningService} from "./page/job-opening/job-opening.service";
import { DecorationsComponent } from './decorations/decorations.component';
import { ShortcutsComponent } from './shortcuts/shortcuts.component';
import { CommonHeaderComponent } from './common-header/common-header.component';
import {PageService} from "./page/page.service";
import {NewsService} from "./news-entry/news.service";
import { NewsEntryComponent } from './news-entry/news-entry.component';
import { EventPageComponent } from './page/event-page/event-page.component';
import {KaSkjerPipe} from "./pipes/ka-skjer-pipe";
import { CardsComponent } from './cards/cards.component';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';
import {AppUtilsService} from "./app-utils.service";
import { GeneralSearchComponent } from './general-search/general-search.component';
import { SearchComponent } from './search/search.component';
import { PageNubComponent } from './page-nub/page-nub.component';
import { DataFilterPipe } from './data-filter.pipe';
import { RecaptchaModule } from 'ng-recaptcha';
import { NewsArchiveComponent } from './news-archive/news-archive.component';
import { NaturglederpostenComponent } from './naturglederposten/naturglederposten.component';
import { CompaniesComponent } from './companies/companies.component';
import {IsiMiscService} from "./isi-misc.service";
import { NewsLetterComponent } from './news-letter/news-letter.component';
import { DesignManualComponent } from './design-manual/design-manual.component';
import {AttachmentService} from "./attachment.service";
import {GoogleService} from "./google.service";
import { OptinComponent } from './optin/optin.component';
import { NaturgledeArchiveComponent } from './naturglede-archive/naturglede-archive.component';
import { LevendelesjaArchiveComponent } from './levendelesja-archive/levendelesja-archive.component';
import { KalenderComponent } from './kalender/kalender.component';
import {SafeHtmlPipe} from './pipes/safe-html-pipe';
import { JobbiraumaComponent } from './jobbirauma/jobbirauma.component';

registerLocaleData(localeNorwegian);

const appRoutes: Routes = [
  { path: '', component: JobbiraumaComponent},
  { path: 'side/jobbirauma', component: JobOpeningsComponent},
  { path: 'kalender', component: KalenderComponent},
  { path: 'jobb/:unid', component: JobOpeningComponent},
  { path: 'arkiv/naturgledeposten', component: NaturgledeArchiveComponent},
  { path: 'arkiv/levendelesja', component: LevendelesjaArchiveComponent},
  { path: 'side/nyhetsarkiv', component: NewsArchiveComponent},
  { path: 'side/naturgledeposten', component: NaturglederpostenComponent},
  { path: 'side/raumabedrifter', component: CompaniesComponent},
  { path: 'side/raumabedrifter/:unid', component: CompaniesComponent},
  { path: 'side/raumaprofil', component: DesignManualComponent},
  { path: 'side/:urlId', component: PageWrapperComponent},
  { path: 'nyhetsbrev/:urlId', component: NewsLetterComponent},
  { path: 'kaskjer/:unid', component: EventPageComponent},
  { path: 'nyhet/:urlId', component: NewsEntryComponent},
  { path: 'kaskjer', component: KaSkjerComponent},
  { path: 'optin', component: OptinComponent},
  { path: 'search/:searchTerm', component: SearchComponent},
  { path: 'previewPage/:urlId', redirectTo:'side/:urlId'},
  { path: 'previewNews/:urlId', redirectTo:'nyhet/:urlId'},
  { path: 'jobbirauma', redirectTo: 'side/jobbirauma'},
  { path: 'rauma/nyhet/:urlId', redirectTo: 'nyhet/:urlId'},
  { path: 'rauma/nyhetsbrev/:urlId', redirectTo: 'nyhetsbrev/:urlId'},
  { path: 'rauma/jobb/:unid', redirectTo: 'jobb/:unid'},
  { path: 'rauma/jobb', redirectTo: 'jobbirauma'},
  { path: 'rauma/info/:urlId', redirectTo: 'side/:urlId'},
  { path: 'kalender/:unid', redirectTo: 'kaskjer/:unid'},
  { path: 'innovasjonsfestivalen/:unid', redirectTo: 'kaskjer#/page/innovasjonsfestival'},

  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    KaSkjerComponent,
    HomeComponent,
    KalenderComponent,
    PageComponent,
    CardComponent,
    JobOpeningComponent,
    JobOpeningsComponent,
    DecorationsComponent,
    ShortcutsComponent,
    CommonHeaderComponent,
    NewsEntryComponent,
    EventPageComponent,
    CardsComponent,
    PageWrapperComponent,
    GeneralSearchComponent,
    SearchComponent,
    PageNubComponent,
    KaSkjerPipe,
    SafeHtmlPipe,
    DataFilterPipe,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    NewsArchiveComponent,
    NaturglederpostenComponent,
    CompaniesComponent,
    NewsLetterComponent,
    DesignManualComponent,
    OptinComponent,
    NaturgledeArchiveComponent,
    LevendelesjaArchiveComponent,
    JobbiraumaComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers:[IsiMiscService,
    KaSkjerService,
    JobOpeningService,
    PageService,
    NewsService,
    KaSkjerPipe,
    DataFilterPipe,
    AppUtilsService,
    Title,
    AttachmentService,
    GoogleService,
    Meta,
    {provide: LOCALE_ID, useValue: "nb-NO" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
