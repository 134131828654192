import {Component, OnInit, AfterViewInit, Input, OnDestroy} from '@angular/core';
import {Page} from "./page";
import {ActivatedRoute} from "@angular/router";
import {PageService} from "./page.service";
import {AppUtilsService} from "../app-utils.service";
import {DatePipe} from "@angular/common";
import {Meta, Title} from "@angular/platform-browser";
import {KaSkjerEvent} from "../ka-skjer/ka-skjer-event";
import {Subscription} from "rxjs/Subscription";
import {environment} from "../../environments/environment";

declare let google:any;

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit,OnDestroy, AfterViewInit{


  noImage:boolean = false;
  @Input() page:Page;
  @Input() imgSrc:string;
  @Input() useLessHeight:boolean;

  useShortcuts:boolean=true;
  url:string;
  urlId:string;
  unid:string;
  pageDate:string;
  blurImage:boolean;
  mediaSubscription:Subscription;
  environment = environment;
  constructor(private pageService:PageService, private activeRoute:ActivatedRoute, private appUtils:AppUtilsService,
              private titleService:Title, private meta: Meta) {
    this.mediaSubscription = this.appUtils.mediaSubject.subscribe(media=>{
      this.onMediaChanged(media);
    })
  }


  getPage(){
    if(!this.page){
      this.pageService.getPage(this.urlId,this.unid).subscribe((page)=>{
        this.page = page;
        this.shouldBlurImage(page);
        this.generatePageDate(page);
      });
    }
    if(this.appUtils.getMedia() == AppUtilsService.MEDIA_MOBILE){
      this.useShortcuts=false;
    }
  }

  /**
   * figure out if the page should have a blurred image by checking if a page is tagged with "hovedside"
   * @param page the loaded page
   */
  shouldBlurImage(page:Page){
    if(page && page.tags){
      for(var tag of page.tags){
        if(tag=="hovedside")this.blurImage=true;
      }
    }
  }

  ngOnInit() {
    if(!this.page){ // get a page if no page is given when using this component
      this.getPage();
    }else{
      this.generatePageDate(this.page);
    }
  }
  ngOnDestroy(){
    this.mediaSubscription.unsubscribe();
  }

  generatePageDate(page:Page){
    this.shouldBlurImage(this.page);
    this.titleService.setTitle(this.page.subject+" – Nordveggen");
    if(page.modified && typeof page.modified == "number"){
      this.pageDate = new DatePipe("nb-NO").transform(page.modified);
    }else if(page.registeredDate && typeof page.registeredDate == "number"){
      this.pageDate = new DatePipe("nb-NO").transform(page.registeredDate);
    }
    if(!page.ImageData || page.ImageData.length<=0){
      this.noImage = true;
    }
  }

  onMediaChanged(media:string):void{
    if(media == AppUtilsService.MEDIA_MOBILE){
      this.useShortcuts = false;
    }else if(media == AppUtilsService.MEDIA_DESKTOP){
      this.useShortcuts = true;
    }else{
      this.useShortcuts = false;
    }
  }
  ngAfterViewInit(){
    this.myMap();
  }
  myMap(){
    var mapCanvas = document.getElementById("map");
    if(!mapCanvas)return;
    var kaSkjerEvent:KaSkjerEvent = this.page as KaSkjerEvent;
    var latLng:any = {lat:Number(kaSkjerEvent.latitude),lng:Number(kaSkjerEvent.longitude)};
    var mapOptions = {
      center: latLng,
      zoom: 15
    };
    var map = new google.maps.Map(mapCanvas, mapOptions);
    var marker = new google.maps.Marker({
      position: latLng,
      map: map
    });

  }
  hasAttachments():boolean{
    this.page.attachments=this.page.attachments||this.page.Attachments;
    return (this.page && this.page.attachments && Array.isArray(this.page.attachments) && this.page.attachments.length>0);
  }

  addMeta() {        
    if (this.page) {
      var imageData = this.page.ImageData;
      const imgUrl = 'https://www.nordveggen.no/'+imageData[0].src;
      
      this.meta.updateTag({ 'property': 'og:image', 'content':  imgUrl});

      this.meta.updateTag({ 'property': 'og:title', 'content' : this.page.subject });
      this.meta.updateTag({ 'property': 'og:description', 'content' : this.page.resume });
    }
  }

}
