<div *ngIf="imageData && imageData.length>0"
     class="page-header page-header-title blur-head"
     [class.page-header-ready]="pageHeaderReady"
     [class.no-blur]="!blur">
  <!--<img [src]="imgSrc">-->
  <canvas id="header-canvas"></canvas>
</div>
<div class="page-header page-header-title"
     [class.no-blur]="!blur">
  <div class="page-header-wrapper">
    <div class="page-header-content">
      <div class="page-header-content-inner">
        <h1>{{title}}</h1>
        <p>{{description}}</p>
      </div>
    </div>
  </div>
  <app-decorations></app-decorations>
</div>
