<article *ngIf="entry" class="entry">
  <div class="entry-image">
    <img *ngIf="imgSrc" [src]="imgSrc" [alt]="entry.subject||entry.Subject">
  </div>
  <div class="entry-content">
    <h2 class="entry-title">
      <a *ngIf="entry && entry.urlId" [routerLink]="'/nyhet/'+entry.urlId">{{entry.subject||entry.Subject}}</a>
      <span *ngIf="entry && !entry.urlId">{{entry.subject||entry.Subject}}</span>
    </h2>
    <div *ngIf="entry.resume||entry.Resume" class="entry-summary">
      {{entry.Resume||entry.resume}}
    </div>
    <div *ngIf="!(entry.resume||entry.Resume) && (entry.Body||entry.body)" class="entry-summary">
      <div [innerHTML]="entry.Body||entry.body"></div>
    </div>
    <div *ngIf="entry && entry.linkUrl" class="entry-read-more">
      <a href="{{entry.linkUrl}}">Mer info</a>
    </div>
    <div class="entry-date">
      {{entry.Created || entry.created | date:"dd MMM yyyy"}}
    </div>
  </div>
</article>
<app-page *ngIf="page" [page]="page" [useLessHeight]="true"></app-page>
