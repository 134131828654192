<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div *ngFor="let question of questions" class="form-row">
      <df-question [question]="question" [form]="form"></df-question>
    </div>
    <re-captcha (resolved)="resolved($event)" siteKey="6LeHEzMUAAAAANMjovt0PxvZY68JYFPSN2ms1Vuf"></re-captcha>
    <div class="form-row">
      <button class="button" type="submit" [disabled]="!form.valid || !isRecapchaed">Meld meg på</button>
    </div>
  </form>
  <div *ngIf="payLoad" class="form-row">
    <!--<strong>Saved the following values</strong><br>{{payLoad}}-->
  </div>
</div>
