import {Component, OnInit, AfterViewInit, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Page} from "../page/page";
import {Card} from "../card/card";
import {AppUtilsService} from "../app-utils.service";
import {Title} from "@angular/platform-browser";
import {Subscription} from "rxjs/Subscription";
@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit,OnDestroy {

  @Input() page:Page;
  @Input() cards:Array<Card>;
  @Input() useCustomOrdering:boolean;
  @Input() blur:boolean;
  noImage:boolean=false;
  urlId:string;
  useShortcuts:boolean = true;
  mediaSubscription:Subscription;
  constructor(private activeRoute:ActivatedRoute, private appUtils:AppUtilsService, private titleService:Title) {
    this.mediaSubscription = this.appUtils.mediaSubject.subscribe(media=>{
      this.onMediaChanged(media);
    });
    this.urlId = activeRoute.snapshot.params["urlId"];
  }
  ngOnInit() {
    if(this.page){
      this.titleService.setTitle(this.page.subject +" – Nordveggen");
      if(!this.page.ImageData || this.page.ImageData.length<=0){
        this.noImage=true;
      }
    }
    if(this.appUtils.getMedia() == AppUtilsService.MEDIA_MOBILE){
      this.useShortcuts=false;
    }
  }
  ngOnDestroy(){
    this.mediaSubscription.unsubscribe();
  }
  onMediaChanged(media:string):void{
    if(media == AppUtilsService.MEDIA_MOBILE){
      this.useShortcuts = false;
    }else if(media == AppUtilsService.MEDIA_DESKTOP){
      this.useShortcuts = true;
    }else{
      this.useShortcuts = false;
    }
  }
}
