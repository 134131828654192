<div class="page-nub" page-nub></div>
<app-common-header *ngIf="page"
  [imageData]="page.ImageData"
  [blur]="true"
  [title]="page.subject"
  [description]="page.Resume">
</app-common-header>
<app-shortcuts></app-shortcuts>

<div class="page-container">
  <div class="page-container-wrapper">
    <main class="page-container-main">
      <article app-news-entry *ngFor="let entry of newsEntries" [entry]="entry"></article>
      <a [routerLink]="'/side/nyhetsarkiv'">Flere nyheter &rarr;</a>
    </main>
    <div [ngClass]="{'page-container-sidebar':media===MEDIA_DESKTOP,'isi-page-container-sidebar':media!==MEDIA_DESKTOP}">
      <h2>Arrangement</h2>
      <ka-skjer type="Nordveggen" [useInSidebar]="true"></ka-skjer>
    </div>
  </div>
</div>
