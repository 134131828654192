import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { NewsCard } from '../news-card';
import { NewsService } from '../news-entry/news.service';
import { IsiMiscService } from '../isi-misc.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-levendelesja-archive',
  templateUrl: './levendelesja-archive.component.html',
  styleUrls: ['./levendelesja-archive.component.css']
})
export class LevendelesjaArchiveComponent implements OnInit, OnDestroy {
  isMobile:boolean = false;
  @HostListener('window:scroll', ['$event'])
  reShuffle(event){
    if(this.isMobile && window.innerWidth>=740){
      this.isMobile=false;
      this.getAllNews();
    }else if(!this.isMobile && window.innerWidth<740){
      this.isMobile=true;
      this.getAllNews();
    }
  }
  shuffleditems:any;
  nyhetList:Array<NewsCard> = [];
  isLoading:boolean = false;
  newsletterSubcription: Subscription;
  constructor(private newsService:NewsService,private isiMisc:IsiMiscService) {
    
   }

  ngOnInit() {
    this.getAllNews();
  }
  getAllNews(){
    this.newsletterSubcription =  this.newsService.getNyhetArchive("LevendeLesja").subscribe(newsEntries=>{
      this.shuffleditems = newsEntries;
      if(!this.isMobile)this.shuffleditems = this.isiMisc.shuffleArray(newsEntries);
      for(var ne of this.shuffleditems){
        var newsCard:NewsCard = new NewsCard(ne.subject,ne.resume,ne.unid,ne.urlId,ne.showDate);
        newsCard.pageLink="nyhetsbrev/"+ne.urlId;
        this.nyhetList.push(newsCard);
      }
    });
  }
  ngOnDestroy() {
    this.newsletterSubcription.unsubscribe();
  }

}
