import {Component, Input, OnInit, EventEmitter, Output}  from '@angular/core';
import { FormGroup }                 from '@angular/forms';
import {QuestionControlService} from "../question-control.service";
import {QuestionBase} from "../question-base";
@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [ QuestionControlService ]
})
export class DynamicFormComponent implements OnInit {
  @Output("onSubmit") fireSubmit = new EventEmitter<any>();
  @Input() questions: QuestionBase<any>[] = [];
  form: FormGroup;
  payLoad = '';
  isRecapchaed:boolean = false;
  recaptcha_challenge = "";
  constructor(public qcs: QuestionControlService) {  }
  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.questions);
  }
  onSubmit() {
    this.payLoad = JSON.stringify(this.form.value);
    var kaSkjerApplyEventData = this.form.value;
    kaSkjerApplyEventData["recaptcha_challenge"]=this.recaptcha_challenge;
    this.fireSubmit.emit(kaSkjerApplyEventData);
  }
  resolved(captchaResponse: string) {
    this.isRecapchaed = true;
    this.recaptcha_challenge = captchaResponse;
  }
}
