import {ImgData} from "../img-data";
import {Attachment} from "../attachment";
export class Page {
    Attachments?:Array<Attachment>;
    attachments?:Array<Attachment>;
    subject?:string;
    Subject?:string;
    Body?:string;
    body?:string;
    form?:string;
    ImageData?:Array<ImgData>;
    images?:Array<ImgData>;
    links?:string;
    Resume?:string;
    resume?:string;
    Status?:string;
    status?:string;
    author?:string;
    byLine?:string;
    created?:string;
    docDate?:string;
    html?:string;
    modified?:string;
    projNum?:string;
    pubDate?:string;
    tags?:Array<string>;
    unid?:string;
    url?:string;
    urlId?:string;
    registeredDate?:string;
    StartDate?:number;
    logoUrl?:string;

}
