import { Component, OnInit, Input } from '@angular/core';
import { KaSkjerService } from "./ka-skjer.service";
import { KaSkjerEvent } from "./ka-skjer-event";
import { KaSkjerPipe } from "../pipes/ka-skjer-pipe";
import { Title } from "@angular/platform-browser";
import { Router, NavigationStart, ActivatedRoute, UrlTree, NavigationEnd } from "@angular/router";
import { QuestionBase } from "../question-base";
import { GoogleService } from "../google.service";
@Component({
  selector: 'ka-skjer',
  templateUrl: './ka-skjer.component.html',
  styleUrls: ['./ka-skjer.component.css'],
})
export class KaSkjerComponent implements OnInit {

  events: Array<KaSkjerEvent>; // all events
  filteredEvents: Array<KaSkjerEvent>; // subset of events
  filteredByCat: Array<KaSkjerEvent>; // subset of filteredEvents
  filteredByLoc: Array<KaSkjerEvent>; // subset of filteredEvents
  filteredByTime: Array<KaSkjerEvent>; // subset of filteredEvents
  filteredByFreeSearch: Array<KaSkjerEvent>; // subset of filteredEvents
  selectedColumn: number = -1;
  inverseSorting: boolean = false;
  categories: Array<string>;
  locations: Array<string>;
  redirectToKaSkjerEventCreationUrl = "https://www.kaskjer.rauma.no/admin";
  unid: string;
  @Input() type: string; //filter used to get a specific event
  @Input() useInSidebar: boolean = false;
  @Input() companyEvents: Array<KaSkjerEvent>;
  @Input() updateEvent: Function; //callbackFunction
  @Input() removeFilter: boolean; 
  constructor(private kaSkjerService: KaSkjerService, private kaSkjerPipe: KaSkjerPipe, private titleService: Title,
    private activeRoute: ActivatedRoute, private router: Router) {
    this.unid = activeRoute.snapshot.params["unid"];
  }

  ngOnInit() {
    if (!this.useInSidebar) {
      this.titleService.setTitle("Ka skjer? – Nordveggen");
    }
    if (this.companyEvents) {
      this.events = this.companyEvents;
      this.filteredEvents = this.events;
    } else {
      this.kaSkjerService.getEvents(this.type).subscribe((data) => {
        this.getCategories();
        this.getLocations();
        this.events = data;
        this.filteredEvents = this.events;
        this.filteredByCat = this.events;
        this.filteredByLoc = this.events;
        this.filteredByTime = this.events;
        this.filteredByFreeSearch = this.events;
      });
    }
  }

  getCategories(): void {
    this.kaSkjerService.getCategories().subscribe(categories => {
      this.categories = categories.sort(function (a, b) {
        if (a == b) return 0;
        else if (a > b) return 1;
        else return -1;
      });
    });
  }
  getLocations(): void {
    this.kaSkjerService.getLocations().subscribe(locations => {
      this.locations = locations;
    });
  }
  selectColumn(columnId: number) {
    if (columnId == this.selectedColumn)
      this.inverseSorting = !this.inverseSorting;
    else
      this.inverseSorting = false;

    this.selectedColumn = columnId;
  }
  sortEvents(fieldName: string): void {
    this.filteredEvents = this.kaSkjerPipe.transform(this.filteredEvents, KaSkjerPipe.TYPE_SORT, fieldName, this.inverseSorting);
  }
  combineFilters(filters: Array<Array<KaSkjerEvent>>) {
    var combinedMap = {};
    for (var i = 0; i < filters.length; i++) {
      for (var j = 0; j < filters[i].length; j++) {

        if (combinedMap.hasOwnProperty(filters[i][j].unid)) {
          var event = combinedMap[(filters[i][j].unid)];
          event.count++;
          combinedMap[filters[i][j].unid] = event;
        } else {
          var event: any = filters[i][j];
          event.count = 1;
          combinedMap[filters[i][j].unid] = event;
        }
      }
    }

    this.filteredEvents = [];
    for (var key in combinedMap) {
      if (combinedMap.hasOwnProperty(key)) {
        var event = combinedMap[key];
        if (event.count == filters.length) {
          delete event.count;
          this.filteredEvents.push(event);
        }
      }
    }
  }
  categoryChange(event: any) {
    var cat: string = event.target.value;
    if (cat == "all") this.filteredByCat = this.events;
    this.filteredByCat = this.kaSkjerPipe.transform(this.events, KaSkjerPipe.TYPE_FILTER, "Categories", this.inverseSorting, cat);
    this.combineFilters([this.filteredByCat, this.filteredByTime, this.filteredByLoc, this.filteredByFreeSearch]);
  }
  locationChange(event: any) {
    var loc: string = event.target.value;
    if (loc == "all") this.filteredByLoc = this.events;
    this.filteredByLoc = this.kaSkjerPipe.transform(this.events, KaSkjerPipe.TYPE_FILTER, "Location", this.inverseSorting, loc);
    this.combineFilters([this.filteredByCat, this.filteredByTime, this.filteredByLoc, this.filteredByFreeSearch]);
  }
  timeIntervalChange(event: any) {
    var time = event.target.value;
    if (time == "all") this.filteredByTime = this.events;
    this.filteredByTime = this.kaSkjerPipe.transform(this.events, KaSkjerPipe.TYPE_DATE, "StartDate", this.inverseSorting, time);
    this.combineFilters([this.filteredByCat, this.filteredByTime, this.filteredByLoc, this.filteredByFreeSearch]);
  }
  search(event: any) {
    var term = event.target.value;
    this.filteredByFreeSearch = this.kaSkjerPipe.transform(this.events, KaSkjerPipe.TYPE_FREE_SEARCH, "", this.inverseSorting, term);
    this.combineFilters([this.filteredByCat, this.filteredByTime, this.filteredByLoc, this.filteredByFreeSearch]);
  }
  changeRoute(unid: string): void {
    if (this.unid != unid) {
      this.unid = unid;
      window.open('https://kaskjer.rauma.no/kaskjer/' + unid, '_blank');

      if (this.updateEvent) {
        this.updateEvent(unid);
      }
    }
  }
  login(): void {
    window.location.href = this.redirectToKaSkjerEventCreationUrl;
  }
  redirectTo(url) {
    try {
      window.open(url, '_blank');
    } catch (err) {
      window.location.href = url;
    }

  }
}
