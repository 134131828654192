<!-- <app-common-header *ngIf="page && page.ImageData && page.ImageData.length>0" [imageData]="page.ImageData" [blur]="blurImage" [title]="blurImage?page.subject:''" [useLessHeight]="useLessHeight"></app-common-header> -->
<div class="headerImg">
    <img src={{imgSrc}} width ="100%" />
</div>
<div *ngIf="allNews" class="page-container">
  <app-cards [cards]="allNews" [page]="null" [useCustomOrdering]="false"></app-cards>
</div>

<div *ngIf="!allNews|| isLoading" class="page-container page">
  <h2>Vennligst vent mens nyhetsarkivet lastes. Dette kan ta litt tid.</h2>
</div>
