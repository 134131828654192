import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { NewsService } from '../news-entry/news.service';
import { NewsCard } from '../news-card';
import { IsiMiscService } from '../isi-misc.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-naturglede-archive',
  templateUrl: './naturglede-archive.component.html',
  styleUrls: ['./naturglede-archive.component.css']
})
export class NaturgledeArchiveComponent implements OnInit,OnDestroy {
  isMobile:boolean = false;
  @HostListener('window:scroll', ['$event'])
  reShuffle(event){
    if(this.isMobile && window.innerWidth>=740){
      this.isMobile=false;
      this.getAllNews();
    }else if(!this.isMobile && window.innerWidth<740){
      this.isMobile=true;
      this.getAllNews();
    }
  }
  allNyhet:Array<NewsCard> = [];
  isLoading:boolean = false;
  newsletterSubcription: Subscription
  constructor(private newsService:NewsService,private isiMisc:IsiMiscService) { }

  ngOnInit() {
   this.getAllNews();
  }
  getAllNews(){
    this.newsletterSubcription =  this.newsService.getNyhetArchive("naturgledepost").subscribe(newsEntries=>{
      var shuffledNews = newsEntries;
      if(!this.isMobile)shuffledNews = this.isiMisc.shuffleArray(newsEntries);
      for(var ne of shuffledNews){
        var newsCard:NewsCard = new NewsCard(ne.subject,ne.resume,ne.unid,ne.urlId,ne.showDate);
        newsCard.pageLink="nyhetsbrev/"+ne.urlId;
        this.allNyhet.push(newsCard);
      }
    });
  }
  ngOnDestroy() {
    this.newsletterSubcription.unsubscribe();
  }

}
