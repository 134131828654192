import { Component, OnInit, AfterViewInit, Input, HostBinding } from '@angular/core';
import { Card } from "./card";
import { Router } from "@angular/router";

@Component({
  selector: '[app-card]', //attribute selector
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() card: Card;
  @HostBinding('class.card') isCard: boolean = true;
  @HostBinding('class.color-mountain2') colorMountain: boolean = false;
  @HostBinding('class.color-forest2') colorForest: boolean = false;
  @HostBinding('class.color-sky') colorSky: boolean = false;
  @HostBinding('class.color-water2') colorWater: boolean = false;

  numColorClasses: number = 4;
  static colorClass: number = 3;
  hasArkiv: boolean = false;
  constructor(private router: Router) {
  }

  private generateColorClass(): void {
    CardComponent.colorClass = (++CardComponent.colorClass % this.numColorClasses);
    switch (CardComponent.colorClass) {
      case 0:
        this.colorMountain = true;
        break;
      case 1:
        this.colorForest = true;
        break;
      case 2:
        this.colorSky = true;
        break;
      case 3:
        this.colorWater = true;
        break;
    }
  }
  /**
     *
     * @param card use the card to determine whether to route to interal or external page.
     */
  ngOnInit() {
    if(this.card.pageLink && this.card.pageLink.includes('arkiv')) {
      this.hasArkiv = true;
    }
    this.generateColorClass();
  }


  routeTo(card: Card): void {
    if (card.pageLink) {
      this.router.navigateByUrl("/" + card.pageLink);
    } else if (card.externalUrl) {
      window.location.href = card.externalUrl;
    }

  }
}
