import { Component, OnInit } from '@angular/core';
import {PageWrapperComponent} from "../page-wrapper/page-wrapper.component";
import {Router, ActivatedRoute} from "@angular/router";
import {PageService} from "../page/page.service";
import {NewsEntry} from "../news-entry/news-entry";
import {GoogleService} from "../google.service";
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.css']
})
export class NewsLetterComponent extends PageWrapperComponent{
  newsItems:Array<NewsEntry> = [];
  constructor(public activatedRoute:ActivatedRoute, public pageService:PageService, public router:Router, public meta: Meta){
    super(activatedRoute,pageService,router, meta);
  }

  getPage(){
    var bodyDom:any = document.getElementsByTagName("body")[0];
    bodyDom.setAttribute("page-status","page-loading");
    return this.pageService.getNewsletterPage(this.urlId).subscribe(res=>{
      this.page = res;
      this.newsItems = res.newsItems;
      bodyDom.setAttribute("page-status","page-loaded");
    });
  }

}
