import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {KaSkjerEvent} from "./ka-skjer-event";
import {TextboxQuestion} from "../textbox-question";
import {QuestionBase} from "../question-base";
import 'rxjs/add/operator/map'
import {environment} from "../../environments/environment";
import { map } from 'rxjs/operators';


@Injectable()
export class KaSkjerService {

  //TODO(john) make a method that we can use in order to replace common code inside getCategories, getLocations and so on.
  categories:Array<string>;
  locations:Array<string>;
  companies:Array<string>;
  events:Array<KaSkjerEvent>;
  keyTaken:boolean = false;
  allItems:any;
  constructor(private http:HttpClient) {

  }

  /**
   * Using a lock in order to prevent threads from calling the server. threads who are late have to wait for the main thread
   * to get the events.
   * @return {any}
   */
  private getAll():Observable<any>{
    if(!this.keyTaken){
      this.keyTaken=true;
      return this.http.get(environment.apiKaskjer+"/events").pipe(map((res: any) =>{
        var items = res.data as any;
        this.categories = items.categories;
        this.locations = items.locations;
        this.companies = items.companies;
        this.events = items.events;
        this.allItems=items;
        this.keyTaken=false;
        return items;
      }));
    }else{
      return new Observable<any>(observer=>{
        this.checkForAllItems(observer);
      })
    }

  }

  /**
   * wait until the main thread is finished.
   * @param observer
   */
  checkForAllItems = function(observer){
    var mySetTimeout = function(observer){
      setTimeout(function(){
        if(this.allItems){
          observer.next(this.allItems);
          observer.complete();
        }else{
          mySetTimeout(observer);
        }
      },1000);
    }
  };

  getAllEvents():Observable<Array<KaSkjerEvent>>{
      return new Observable<Array<KaSkjerEvent>>(observer=>{
          if(this.events){
              observer.next(this.events);
              observer.complete();
          }else{
              this.getAll().subscribe((items) => {
                  observer.next(items.events);
                  observer.complete();
              });
          }
      });
  }
  getCategories():Observable<Array<any>>{
      return new Observable<Array<any>>(observer =>{
          if(this.categories){
              observer.next(this.categories);
              observer.complete();
          }
          else{
              this.getAll().subscribe((items)=>{
                  observer.next(items.categories);
                  observer.complete();
              });
          }
      });
  }
  getLocations():Observable<Array<any>>{
      return new Observable<Array<any>>(observer =>{
          if(this.locations){
              observer.next(this.locations);
              observer.complete();
          }
          else{
              this.getAll().subscribe((items)=>{
                  observer.next(items.locations);
                  observer.complete();
              });
          }
      });
  }
  /**
   *
   * @param type if no type is given, get all events.
   * @return {any}
   */
  getEvents(type:string):Observable<Array<KaSkjerEvent>>{
    if(!type) return this.getAllEvents();
    var url:string=environment.apiKaskjer+"/events";
    if(type) url+="?arr="+type+"&type=all";
    return this.http.get(url).pipe(map((res: any) => res.data.events as Array<KaSkjerEvent>));
  }
  getEvent(unid:string):Observable<KaSkjerEvent>{
    return this.http.get(environment.apiKaskjer+"/events/"+unid).pipe(map((res:any)=> res.data as KaSkjerEvent));
  }
  applyForKaSkjerEvent(data):Observable<any>{
    return this.http.post(environment.apiKaskjer+"/signup",data);
  }
  getApplyForKaSkjerEventQuestions():Array<QuestionBase<any>>{
    return [
      new TextboxQuestion({
        key: 'Name',
        placeholder: 'Navn',
        label: 'Navn',
        type:"text",
        required: true,
        order: 1
      }),
      new TextboxQuestion({
        key: 'email',
        placeholder: 'Email',
        label: 'Email',
        required: true,
        type: 'email',
        order: 2
      }),
      new TextboxQuestion({
        key: 'phone',
        placeholder: 'Telefon',
        label: 'Telefon',
        required: true,
        type: 'number',
        order: 3
      }),
      new TextboxQuestion({
        key: 'Company',
        placeholder: 'Firma',
        label: 'Firma',
        type: 'text',
        order: 4
      })
    ];
  }

}
