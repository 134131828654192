import { Injectable } from '@angular/core';

@Injectable()
export class IsiMiscService {

  constructor() { }

  /**
   * Because of the way css-measonary works, we have to shuffle the sorting of the array so that it gets the order
   * 1,3,5,7,9... instead of 1,2,3,4,5,6.
   * @param oldArray
   * @return {Array}
   */
  shuffleArray(oldArray:Array<any>){
    //TODO(john) support more than two columns.
    // var shuffledArray = [];
    // for(var i = 0; i<oldArray.length+2; i = i+2){
    //   if(oldArray[i]){
    //     shuffledArray.push(oldArray[i]);
    //   }
    // }
    // for(var i = 1; i<oldArray.length; i=i+2){
    //   if(oldArray[i]){
    //     shuffledArray.push(oldArray[i]);
    //   }
    // }
    return oldArray;
  }

}
