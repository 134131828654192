import {Component, OnInit} from '@angular/core';
// import {HttpClient} from "@angular/common/http";
import {AppUtilsService} from "./app-utils.service";
import {Router, NavigationEnd} from "@angular/router";
import {GoogleService} from "./google.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class AppComponent{
  constructor(private appUtils:AppUtilsService, private router:Router, private googleService:GoogleService){
    this.router.events.subscribe((event)=>{
      if(event instanceof NavigationEnd){
        window.scrollTo(0,0);
        googleService.sendPageView(event.urlAfterRedirects);
      }
    });
  }
  /**
   * toggles a "navigation-open"-class to the body element
   * Triggers when user clicks the hamburger menu.
   */
  toggleNavigation(event:Event):void{
    var bodyDom:any = document.getElementsByTagName("body")[0];
    if(bodyDom.className===""){
      bodyDom.className="navigation-open";
    }else{
      bodyDom.className="";
    }
    if(event)event.stopPropagation();
  }

  closeNavigationMenu(event){
    var bodyDom:any = document.getElementsByTagName("body")[0];
    bodyDom.className="";
    if(event)event.stopPropagation();
  }

  /**
   * Triggered when the user clicks on the document page.
   * @param event
   */
  onClick(event) {
    var classHit:string = event.target.getAttribute("class");
    var bodyDom:any = document.getElementsByTagName("body")[0];
    if(classHit && !classHit.match("navigation") && !classHit.match("search") && bodyDom.className == "navigation-open"){
      bodyDom.className="";
    }
  }
  globalSearch(event,value){
    //TODO(john) Seach databases. change view, with component. List entries.
    // this.router.navigate(['/search', { searchTerm:value}]);
    value = value.replace(/ /g,"_");
    this.router.navigateByUrl("/search/"+value);
    this.closeNavigationMenu(event);
  }

}
