import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decorations',
  templateUrl: './decorations.component.html',
  styleUrls: ['./decorations.component.css']
})
export class DecorationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
