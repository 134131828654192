import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe used for filtering multiple columns in a grid.
 */
@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.filter(item =>{
      var matchesAll = true;
      for(var i = 0; i<args.length; i++){
        // check if your data contains the column and the value defined in args.
        if(item.hasOwnProperty(args[i][0]) && item[args[i][0]]==args[i][1]){
          continue;
        }else{ // at least one column did not match,
          matchesAll = false;
        }
      }
      return matchesAll;
    });
  }

}
