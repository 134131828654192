<!-- <div class="headerImg">
  <img src={{imgSrc}} width="100%" />
</div> -->
<div class="header">
  <h1 class="heading">Bedrifter i Rauma</h1>
</div>
<div class="filter">
  <form [formGroup]="filterForm">
    <!-- <label for="name">Filter: </label> -->
    <input id="filter" type="text" formControlName="filterCtrl" placeholder="Filter" />
    <button class="close-icon" type="reset"></button>
  </form>
</div>

<div *ngIf="allPosts" class="page-container">
  <app-cards [cards]="allPosts" [page]="null" [useCustomOrdering]="true"></app-cards>
</div>


<div *ngIf="!allPosts|| isLoading" class="page-container page">
  <h2>Vennligst vent mens bedrifter lastes. Dette kan ta litt tid.</h2>
</div>