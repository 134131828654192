import {Component, OnInit, Input} from '@angular/core';
import {Page} from "../page/page";

@Component({
  selector: 'app-general-search',
  templateUrl: './general-search.component.html',
  styleUrls: ['./general-search.component.css']
})
export class GeneralSearchComponent implements OnInit {

  @Input()pages:Array<Page>;
  constructor() { }

  ngOnInit() {
  }

}
