import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {JobOpeningService} from "./job-opening.service";
import {JobOpening} from "./job-opening";

@Component({
  selector: 'app-job-opening',
  templateUrl: './job-opening.component.html',
  styleUrls: ['./job-opening.component.css']
})
export class JobOpeningComponent implements OnInit {

  job:JobOpening;
  unid:string;

  constructor(private activeRoute:ActivatedRoute, private jobService:JobOpeningService) {
    this.unid=activeRoute.snapshot.params["unid"];
  }


  getJob():void{
    if(this.unid){
      this.jobService.getJob(this.unid).subscribe((job)=>{
        if(job && job.applicationUrl && job.applicationUrl.match(/^www/)){ //add http:// if not present
          job.applicationUrl = "http://"+job.applicationUrl;
        }
        if(job && job.companyUrl&& job.companyUrl.match(/^www/)){ //add http:// if not present
          job.companyUrl = "http://"+job.companyUrl;
        }
        this.job=job;
      });
    }
  }
  ngOnInit() {
    this.getJob();
  }

}
