<div class="page-header-decoration">

  <svg class="symbol-right" viewBox="-9879.557 4427.44 140.557 162.299">
    <g class="symbol-right-group" transform="translate(-9879.557 4427.44)">
      <path class="symbol-right-path" d="M0,45.69l140.556,81.148v-162.3Z" transform="translate(0 35.461)"/>
    </g>
  </svg>


  <svg class="symbol-left" viewBox="-9879.557 4427.44 140.557 162.299">
    <g class="symbol-left-group" transform="translate(-9879.557 4427.44)">
      <path class="symbol-left-path" d="M0,45.69l140.556,81.148v-162.3Z" transform="translate(0 35.461)"/>
    </g>
  </svg>

</div>