import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Attachment} from "./attachment";
import {environment} from "../environments/environment";
import { map } from 'rxjs/operators';

@Injectable()
export class AttachmentService {

  constructor(private http:HttpClient) { }

  getAttachmentsByTag(tag:string):Observable<Array<Attachment>>{
    return this.http.get(environment.api+"/attachments/categories/"+tag).pipe(map((res:any) =>{
      return res.data as Array<Attachment>
    }));
  }
}
