<app-page *ngIf="event" [page]="event">
  <div class="page-extra">
    <div class="row">
      <div class="medium-12 large-8 columns">
        <img *ngIf="event.image" [src]="event.image">
        <div class="row entry-date">
          <div class="small-4 columns">
            <i class="icon-location-pin"></i>
            Sted: {{event.Location}}
          </div>
          <div class="small-4 columns">
            <i class="icon-calendar"></i>
            <a [href]="'ical.xsp?unid='+event.unid" data-toggle="tooltip" title="Klikk for å laste ned kalenderoppføring.">
              {{event.StartDate | date: 'ddMMyyyy, HH:mm'}}
            </a>
          </div>
          <div class="small-4 columns">
            <i class="icon-wallet"></i>
            Pris: {{event.Price}}
          </div>
        </div>
        <hr>
        <div [innerHTML]="eventBody"></div>
        <div *ngIf="event.signup">
          <hr>
          <h3 *ngIf="showApplicationForm">Påmelding</h3>
          <dynamic-form *ngIf="showApplicationForm" [questions]="questions" (onSubmit)="applyForKaSkjerEvent($event)"></dynamic-form>
          <div *ngIf="!showApplicationForm && applicationSuccessful">
            Takk for påmeldingen
            <button class="button" type="submit" (click)="prepareNewSignup()">Ny påmelding</button>
          </div>

          <div *ngIf="isProcessingSignup"> Vennligst vent på svar </div>
          <hr>
        </div>
        <div *ngIf="event.signUps">
          <h3 class="clickable" (click)="toggleDisplayParticipants()"><span class="simple icon-list"></span> Deltakerliste</h3>
          <div *ngIf="event && event.signUps && displayParticipants">
            <div *ngFor="let participant of event.signUps" class="row">
              <div class="small-6 columns">
                {{participant.fullName}}
              </div>
              <div class="small-6 columns">
                {{participant.organization}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="small-3 columns">
        <aside class="page-container-sidebar">
          <h2 *ngIf="event.Company && event.Company.Name">{{event.Company.Name}}</h2>
          <ul>
            <li *ngIf="event.Company && event.Company.Address">{{event.Company.Address}}</li>
            <li *ngIf="event.Company && event.Company.PostCode">{{event.Company.PostCode}}</li>
            <li *ngIf="event.Company && event.Company.Location">{{event.Company.Location}}</li>
          </ul>
          <div *ngIf="event.latitude && event.longitude" id="map" style="width:100%;height:180px"></div> <!-- see page.component for initializing-->
          <a id="bigMapAnchor" [href]="'http://maps.google.com/?q=loc:'+event.latitude+','+event.longitude">
            <i class="icon-location-pin"></i>Stort kart
          </a>
          <h2>Flere Arrangement</h2>
          <ka-skjer [useInSidebar]="true" [companyEvents]="event.companyEvents" [type]="'Nordveggen'" [updateEvent]="updateEvent"></ka-skjer>
        </aside>
      </div>
    </div>
  </div>
</app-page>
