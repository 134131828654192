import { Component, OnInit } from '@angular/core';
import {Card} from "../card/card";
import {JobOpeningService} from "../page/job-opening/job-opening.service";
import {PageService} from "../page/page.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Page} from "../page/page";
import {Title} from "@angular/platform-browser";
import {GoogleService} from "../google.service";

@Component({
    selector: 'app-job-openings',
    templateUrl: './job-openings.component.html',
    styleUrls: ['./job-openings.component.css']
})
export class JobOpeningsComponent implements OnInit {


    jobOpenings:Array<Card>;
    page:Page;
    urlId:string = "jobbirauma";
    constructor(public jobOpeningService:JobOpeningService, public pageService:PageService, public activatedRoute:ActivatedRoute,
                public titleService:Title) {
    }

    ngOnInit() {
      this.titleService.setTitle("Ledige stillinger – Nordveggen");
      if(!this.page){
        this.pageService.getPage(this.urlId,"").subscribe(page=>{
          this.page = page;
        });
      }
      if(!this.jobOpenings || this.jobOpenings.length<=0){
        this.getJobOpenings();
      }
    }

    getJobOpenings():void{
        this.jobOpenings=[];
        this.jobOpeningService.getJobOpenings().subscribe((openings)=>{
            for(var i:number = 0; i<openings.length; i++ ){
              if(openings[i] && openings[i].company){
                openings[i].extraHeader = openings[i].company +" søker:";
              }
              this.jobOpenings.push(openings[i]);
            }
        });
    }
}
