<!-- The job displayed as page-->
<div class="row job-opening-page-header">
  <div class="small-7 small-centered columns">
    <img *ngIf="job && job.logoUrl" [src]="job.logoUrl" >
    <p *ngIf="job">{{job.companyIntro}}</p>
  </div>
</div>
<app-page *ngIf="job" [page]="job">
    <div class="page-extra">
      <div class="row">
        <div class="small-12">
          <span *ngIf="job.applicationDate">Søknadsfrist: {{job.applicationDate?job.applicationDate:""}}</span>
        </div>
        <div class="small-12">
          <span *ngIf="job.companyUrl"><a [href]="job.companyUrl" target="_blank">Selskapets nettsted</a></span>
        </div>
      </div>
      <br>
    </div>
  <div class="page-extra-after-body">

    <div class="row">
      <div *ngIf="job.applicationUrl" class="small-12">
        <a [href]="job.applicationUrl" target="_blank">Elektronisk søknadsskjema</a>
      </div>
      <div class="small-12">
        <span *ngIf="job.applicationDate">Søknadsfrist: {{job.applicationDate?job.applicationDate:""}}</span>
      </div>
    </div>
    <h3 *ngIf="job.contact">Kontakt:</h3>
    <div *ngIf="job.contact">{{job.contact}}</div>
    <div *ngIf="job.contactPhone">Tlf: {{job.contactPhone}}</div>
    <div *ngIf="job.email">E-post: <a [href]="'mailto:'+job.email">{{job.email}}</a></div>
  </div>
</app-page>

