<ul class="shortcuts" [class.collapsed]="isCollapsed" [class.page]="isPage">

  <li class="color-fall2">
    <a routerLink="/side/naeringsliv" (click)="closeNavigationMenu($event)"><span class="simple icon-rocket"></span><span class="text">Næringsliv</span></a>
  </li>

  <li class="color-forest2">
    <a routerLink="/side/hoppid" (click)="closeNavigationMenu($event)"><span class="simple icon-wrench"></span><span class="text">Etablere bedrift</span></a>
  </li>

  <li class="color-mountain2">
    <a routerLink="/kaskjer" (click)="closeNavigationMenu($event)"><span class="simple icon-calendar"></span><span class="text">Ka skjer?</span></a>
  </li>

  <li class="color-water2">
    <a routerLink="/side/jobbirauma" (click)="closeNavigationMenu($event)"><span class="simple icon-people"></span><span class="text">Ledige stillinger</span></a>
  </li>

  <li class="color-sky2">
    <a routerLink="/optin" (click)="closeNavigationMenu($event)"><span class="simple icon-envelope"></span><span class="text">Nyhetsbrev</span></a>
  </li>

</ul>
