<app-common-header *ngIf="page"
  [blur]="true"
  [title]="page.subject"
  [description]="page.Resume"
  [imageData]="page.ImageData">
</app-common-header>

<app-shortcuts [isPage]="true" [isCollapsed]="true"></app-shortcuts>

<div class="page-container">
  <div class="page-container-wrapper">
    <main class="page-container-wide">
      <div class="cards-container cards-container-maesonry">
          <article app-card *ngFor="let job of jobOpenings" [card]="job"></article>
      </div>
    </main>
  </div>
</div>
