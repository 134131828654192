import {Component, OnInit, HostListener, Input} from '@angular/core';
import {NewsService} from "../news-entry/news.service";
import {Card} from "../card/card";
import {IsiMiscService} from "../isi-misc.service";
import {NewsCard} from "../news-card";
import {NavigationEnd, Router} from "@angular/router";
import {GoogleService} from "../google.service";
import { Page } from '../page/page';
import { PageService } from '../page/page.service';
import { Meta, Title } from '@angular/platform-browser';
import { AppUtilsService } from '../app-utils.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-news-archive',
  templateUrl: './news-archive.component.html',
  styleUrls: ['./news-archive.component.css']
})
export class NewsArchiveComponent implements OnInit {
  @Input() page:Page;
  noImage:boolean = false;
  isMobile:boolean = false;
  blurImage:boolean;
  useShortcuts:boolean=true;
  pageDate:string;
  imgSrc:string;
  @HostListener('window:scroll', ['$event'])
  reShuffle(event){
    if(this.isMobile && window.innerWidth>=740){
      this.isMobile=false;
      this.getAllNews();
    }else if(!this.isMobile && window.innerWidth<740){
      this.isMobile=true;
      this.getAllNews();
    }
  }

  allNews:Array<NewsCard> = [];
  isLoading:boolean = false;
  constructor(private newsService:NewsService,private pageService:PageService, private isiMisc:IsiMiscService,private appUtils:AppUtilsService, private titleService:Title, private meta: Meta) {
  
  }

  ngOnInit() {
    if(!this.page){ // get a page if no page is given when using this component
      this.getPage();
    }else{
      this.generatePageDate(this.page);
    }
    this.getAllNews();
  }
  getPage(){
    if(!this.page){
      this.pageService.getPage('nyhetsarkiv',"").subscribe((page)=>{
        this.page = page;
        this.shouldBlurImage(page);
        this.generatePageDate(page);
        this.addMeta();
      });
    }
    if(this.appUtils.getMedia() == AppUtilsService.MEDIA_MOBILE){
      this.useShortcuts=false;
    }
  }
  /**
   * figure out if the page should have a blurred image by checking if a page is tagged with "hovedside"
   * @param page the loaded page
   */
  shouldBlurImage(page:Page){
    if(page && page.tags){
      for(var tag of page.tags){
        if(tag=="hovedside")this.blurImage=true;
      }
    }
  }
  generatePageDate(page:Page){
    this.shouldBlurImage(this.page);
    this.titleService.setTitle(this.page.subject+" – Nordveggen");
    if(page.modified && typeof page.modified == "number"){
      this.pageDate = new DatePipe("nb-NO").transform(page.modified);
    }else if(page.registeredDate && typeof page.registeredDate == "number"){
      this.pageDate = new DatePipe("nb-NO").transform(page.registeredDate);
    }
    if(!page.ImageData || page.ImageData.length<=0){
      this.noImage = true;
    }
   this.imgSrc = "/bilder/" + page.ImageData[0].urlId +"/$File/xlarge.jpg";
  }
  
  getAllNews(){
    this.newsService.getAllNews().subscribe(newsEntries=>{
      var shuffledNews = newsEntries;
      if(!this.isMobile)shuffledNews = this.isiMisc.shuffleArray(newsEntries);
      for(var ne of shuffledNews){
        var newsCard:NewsCard = new NewsCard(ne.subject,ne.resume,ne.unid,ne.urlId,ne.showDate);
        newsCard.pageLink="side/"+ne.urlId;
        this.allNews.push(newsCard);
      }
    });
  }

  addMeta() {        
    if (this.page) {
      var imageData = this.page.ImageData;
      const imgUrl = 'https://www.nordveggen.no/'+imageData[0].src;
      
      this.meta.updateTag({ 'property': 'og:image', 'content':  imgUrl});

      this.meta.updateTag({ 'property': 'og:title', 'content' : this.page.subject });
      this.meta.updateTag({ 'property': 'og:description', 'content' : this.page.resume });
    }
  }

}
