import {Component, OnInit, AfterViewInit} from '@angular/core';
import {KaSkjerService} from "../../ka-skjer/ka-skjer.service";
import {RouterLinkActive, ActivatedRoute, Router, NavigationStart, UrlTree} from "@angular/router";
import {KaSkjerEvent} from "../../ka-skjer/ka-skjer-event";
import {Title} from "@angular/platform-browser";
import {FormBuilder, Validators, FormGroup, FormControl} from "@angular/forms";
import {QuestionBase} from "../../question-base";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.css']
})
export class EventPageComponent implements OnInit {

  unid:string;
  event:KaSkjerEvent;
  eventBody:string;
  isRecaptchaed:boolean = false;
  applicationSuccessful:boolean = false;
  showApplicationForm:boolean = true;
  displayParticipants:boolean = false;
  questions:Array<QuestionBase<any>> = [];
  isProcessingSignup:boolean = false;
  constructor(private kaskjerService:KaSkjerService, private activeRoute:ActivatedRoute) {
    this.unid=activeRoute.snapshot.params["unid"];

  }

  ngOnInit() {
    this.getEvent(this.unid);
    this.updateEvent=this.updateEvent.bind(this); //scope this.
    this.initApplyForKaSkjerEventQuestions();
    this.toggleDisplayParticipants();
  }
  updateEvent(unid:string):void{
    this.getEvent(unid);
  }
  getEvent(unid:string):void{
    this.kaskjerService.getEvent(unid).subscribe(event=>{
      this.eventBody = event.Body;
      event.Body="";
      this.event=event;
      if(this.event && this.event.image){
        this.event.image = this.event.image.replace("imgById",environment.ksbilder);
      }
      this.event.subject=event.Subject;
    });
  }
  applyForKaSkjerEvent(kaSkjerEventData){
    this.isProcessingSignup=true;
    kaSkjerEventData["event"]=this.event.unid;
    this.kaskjerService.applyForKaSkjerEvent(kaSkjerEventData).subscribe(res=>{
      if(res.status==="valid" || res.status == 200){
        this.applicationSuccessful=true;
        this.showApplicationForm=false;
        this.getEvent(this.event.unid);
      }else{
        this.applicationSuccessful=false;
      }
      this.isProcessingSignup = false;
    });
  }
  prepareNewSignup(){
    this.showApplicationForm=true;
    this.applicationSuccessful=false;
    this.initApplyForKaSkjerEventQuestions();
  }

  toggleDisplayParticipants(){
    this.displayParticipants=!this.displayParticipants;
  }
  initApplyForKaSkjerEventQuestions(){
    this.questions = this.kaskjerService.getApplyForKaSkjerEventQuestions();
  }
}
