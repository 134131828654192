import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {PageService} from "../page/page.service";
import {Card} from "../card/card";
import {Title} from "@angular/platform-browser";
import {GoogleService} from "../google.service";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  searchTerm:string;
  searchEntries:Array<Card>;
  isLoading:boolean = false;
  constructor(private activatedRoute: ActivatedRoute, private pageService: PageService, private router:Router,
              private titleService:Title) {
    this.searchTerm = this.activatedRoute.snapshot.params["searchTerm"];
    this.router.events.subscribe((val)=>{
      this.searchTerm = this.activatedRoute.snapshot.params["searchTerm"];
      if(val instanceof NavigationEnd){
        this.updateSearch();
      }
    });
  }

  ngOnInit() {
    this.updateSearch();
  }

  updateSearch(){
    var searchTerm = this.searchTerm.replace(/_/g," ");
    this.titleService.setTitle(searchTerm+" – Nordveggen");
    this.isLoading=true;
    this.pageService.getPagesBySeachTerm(searchTerm).subscribe(data=>{
      this.isLoading=false;
      for(var i = 0; i<data.length; i++){
        data[i].pageLink="/side/"+data[i].urlId;
      }
      this.searchEntries=data;
    });
  }

}
