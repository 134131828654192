import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NewsEntry} from "./news-entry";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import { take } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable()
export class NewsService {

  allNews:Array<NewsEntry>;
  allNewsLetters:Array<NewsEntry>;
  constructor(private http: HttpClient) {

  }

  getNews(latestNumberOfNews:number):Observable<Array<NewsEntry>>{
    var url:string = environment.api+"/web/articles";
    if(latestNumberOfNews) url+="/"+latestNumberOfNews;
    return this.http.get(url).pipe(map((res:any) => res.data as Array<NewsEntry>));
  }

  getNewsEntry(unid:string):Observable<NewsEntry>{
    return this.http.get(environment.api+"/web/"+unid).pipe(map((res:any) => res.data as NewsEntry));
  }
  getAllNews():Observable<Array<NewsEntry>>{
    return new Observable<any>(observer=>{
      if(!this.allNews){
        this.http.get(environment.api+"/web/articles").pipe(take(1)).subscribe((res:any)=>{
          this.allNews = res.data as Array<NewsEntry>;
          this.allNews = this.allNews.sort(function(a,b){
            return (new Date(b.showDate||b.created).getTime() - new Date(a.showDate||a.created).getTime());
          });
          observer.next(this.allNews);
          observer.complete();
        });
      }else{
        observer.next(this.allNews);
        observer.complete();
      }
    });
  }
  getAllNewsletters():Observable<Array<NewsEntry>>{
    return new Observable<Array<NewsEntry>>(observer=>{
      if(this.allNewsLetters){
        observer.next(this.allNewsLetters);
        observer.complete();
      }else{
        this.http.get(environment.api+"/web/newsletters").subscribe((res:any)=>{
          this.allNewsLetters= res.data as Array<NewsEntry>;
          observer.next(this.allNewsLetters);
          observer.complete();
        });
      }
    });

  }
  getNyhetArchive(filterText): Observable<Array<NewsEntry>> {
    return this.http.get(environment.api + '/web/newsletters?filter=' + filterText).pipe(map((res: any) => {
      return res.data as Array<NewsEntry>;
    }))
  }
}
