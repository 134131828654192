import {Component, OnInit, Input} from '@angular/core';
import {NewsEntry} from "./news-entry";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {PageService} from "../page/page.service";
import {Page} from "../page/page";
import {AppUtilsService} from "../app-utils.service";
import {GoogleService} from "../google.service";
import {environment} from "../../environments/environment";
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: '[app-news-entry]',
  templateUrl: './news-entry.component.html',
  styleUrls: ['./news-entry.component.css']
})
export class NewsEntryComponent implements OnInit {

  @Input() entry:NewsEntry;
  @Input() page:Page;
  urlId:string;
  imgSrc:string;
  
  private title: Title;
  constructor(private activeRoute:ActivatedRoute, private pageService:PageService, private appUtil:AppUtilsService, private meta:Meta,) {
    this.urlId = activeRoute.snapshot.params["urlId"];
  }

  generateImgSrcFromImageData(){
    if(this.entry){
      var imageData = this.entry.ImageData||this.entry.imageData;
      if(imageData && imageData.length>0){
        var img = imageData[0];
        var imgSize="normal";
        if(this.appUtil.getMedia()>=AppUtilsService.MEDIA_DESKTOP){
          imgSize="xxlarge";
        }else if(this.appUtil.getMedia()>=AppUtilsService.MEDIA_TABLET){
          imgSize="xlarge";
        }
        // some services have different name for fileExtension, therefore added type attribute
        if(img.fileExtension){
          this.imgSrc = environment.bilder+"/" + img.urlId +"/$File/"+imgSize+"."+img.fileExtension;
        }else if(img.type){
          this.imgSrc = environment.bilder+"/" + img.urlId +"/$File/"+imgSize+"."+img.type;
        }
      }
    }
  }
  ngOnInit() {
    this.generateImgSrcFromImageData();
    if(!this.page){
      this.getNewsEntry();
    } else {
      this.addMeta();
    }    
  }

  addMeta() {        
    if (this.page) {
      var imageData = this.page.ImageData;
      const imgUrl = 'https://www.nordveggen.no/'+imageData[0].src;
      
      this.meta.updateTag({ 'property': 'og:image', 'content':  imgUrl});

      this.meta.updateTag({ 'property': 'og:title', 'content' : this.page.subject });
      this.meta.updateTag({ 'property': 'og:description', 'content' : this.page.resume });
    }
  }

  getNewsEntry():void{
    if(this.urlId && !this.entry){
      this.pageService.getPage(this.urlId,"").subscribe((page)=>{
        this.page=page;
        this.addMeta();
      });
    }
  }

}
