import {Injectable} from '@angular/core';
import {Subject} from "rxjs/Subject";
@Injectable()
export class AppUtilsService{
  public static MEDIA_MOBILE:string = "mobile";
  public static MEDIA_DESKTOP:string = "desktop";
  public static MEDIA_TABLET:string = "tablet";
  screenWidth:number;
  media:string = AppUtilsService.MEDIA_DESKTOP;
  mediaSubject = new Subject<string>();

  constructor() {
    this.screenWidth=window.innerWidth;
    if(this.screenWidth<=740){
      this.media=AppUtilsService.MEDIA_MOBILE;
    }else if(this.screenWidth<=1024){
      this.media=AppUtilsService.MEDIA_TABLET;
    }else{
      this.media=AppUtilsService.MEDIA_DESKTOP;
    }
    this.mediaSubject.next(this.media);
    window.addEventListener('resize', (event:any) => {
      this.setScreenWidth(event);
      if(event && event.target && event.target.innerWidth && event.target.innerWidth <= 740){
        if(this.media!==AppUtilsService.MEDIA_MOBILE){
          this.media=AppUtilsService.MEDIA_MOBILE;
          this.mediaSubject.next(this.media);
        }
      }else if(event && event.target && event.target.innerWidth && event.target.innerWidth <= 1024){
        if(this.media!==AppUtilsService.MEDIA_TABLET){
          this.media=AppUtilsService.MEDIA_TABLET;
          this.mediaSubject.next(this.media);
        }
      }else if(event && event.target && event.target.innerWidth && event.target.innerWidth > 1051){
        if(this.media!==AppUtilsService.MEDIA_DESKTOP){
          this.media=AppUtilsService.MEDIA_DESKTOP;
          this.mediaSubject.next(this.media);
        }
      }
    });
  }

  setScreenWidth(event:any){
    if(event && event.target && event.target.innerWidth){
      this.screenWidth = event.target.innerWidth;
    }
  }
  getMedia():string{
    return this.media;
  }

}
