<!--[if lt IE 8]>
<p class="browserupgrade">Du bruker en <strong>utdatert</strong> nettleser. Vennligst <a href="http://browsehappy.com/">oppgrader din nettleser</a> for å forbedre din opplevelse.</p>
<![endif]-->

<div class="main-navigation">
  <button class="navigation-trigger" (click)="toggleNavigation($event)">
		<span class="lines-box">
			<span class="lines"></span>
		</span>
    <!-- <span class="screen-reader-text">Åpne hovedmeny</span> -->
  </button>

  <a routerLink="/" title="Gå til hovedsiden" class="branding">

    <svg class="logo" x="0px" y="0px" viewBox="0 0 602 127.5" enable-background="new 0 0 602 127.5" xml:space="preserve">
<g>
	<g class="logo-symbol">
		<path fill="#7A9AAD" d="M11.8,107.2c0,0-0.5-18.8,24.2-29.4c36.5-15.7,95-18.2,75.5-72.1c0,0,1.8,31.1-31.6,30.5
			c-27.2-0.4-41.1,1.8-55.1,9.7C3.9,57.7-4.3,82,11.8,107.2z"/>
		<path fill="#7A9AAD" d="M110.9,0c0,0,18.4,21.8,3.3,49C97.9,78.6,40.5,80.2,29,108.1c-2.4,5.9-1.5,16.6,19.9,15.8
			c16.2-0.6,77.4-16.6,79.4-70.7C129.9,7.9,110.9,0,110.9,0z"/>
	</g>
	<g class="logo-name">
		<path fill="#00567C" d="M158.7,45.3c0-3.7-0.1-6.6-0.3-9.4h5.6l0.4,5.7h0.1c1.7-3.2,5.8-6.5,11.5-6.5c4.8,0,12.3,2.9,12.3,14.8
			v20.8H182V50.6c0-5.6-2.1-10.4-8.1-10.4c-4.1,0-7.3,3-8.5,6.5c-0.3,0.8-0.4,1.9-0.4,3v21h-6.3V45.3z"/>
		<path fill="#00567C" d="M212.8,71.5c-9.4,0-16.7-6.9-16.7-17.9c0-11.7,7.7-18.5,17.3-18.5c10,0,16.8,7.3,16.8,17.9
			C230.2,65.9,221.2,71.5,212.8,71.5L212.8,71.5z M213,66.7c6,0,10.6-5.7,10.6-13.6c0-5.9-3-13.3-10.4-13.3
			c-7.4,0-10.7,6.9-10.7,13.5C202.5,61,206.8,66.7,213,66.7L213,66.7z"/>
		<path fill="#00567C" d="M238.2,46.7c0-4.1-0.1-7.6-0.3-10.9h5.5l0.3,6.8h0.2c1.6-4.7,5.5-7.6,9.7-7.6c0.6,0,1.2,0.1,1.7,0.2v5.9
			c-0.7-0.1-1.4-0.1-2.2-0.1c-4.5,0-7.6,3.4-8.5,8.1c-0.1,0.9-0.2,1.9-0.2,3v18.6h-6.3L238.2,46.7z"/>
		<path fill="#00567C" d="M291.4,19.6v42.1c0,3.1,0.1,6.6,0.3,9H286l-0.3-6h-0.1c-1.9,3.9-6.1,6.8-11.8,6.8c-8.4,0-15-7.1-15-17.7
			C258.7,42.1,266,35,274.4,35c5.4,0,9,2.5,10.5,5.3h0.1V19.6H291.4z M285,50c0-0.9-0.1-1.9-0.3-2.7c-0.9-4-4.4-7.3-9.1-7.3
			c-6.6,0-10.4,5.8-10.4,13.4c0,7.1,3.5,13,10.3,13c4.2,0,8.1-2.9,9.3-7.6c0.2-0.9,0.3-1.7,0.3-2.7V50z"/>
		<path fill="#00567C" d="M304.3,35.8l6.8,19.5c1.2,3.2,2.1,6.1,2.8,9h0.2c0.8-2.9,1.8-5.8,3-9l6.8-19.5h6.6l-13.7,34.8h-6
			l-13.2-34.8H304.3z"/>
		<path fill="#00567C" d="M339.2,54.3c0.1,8.6,5.5,12.1,11.9,12.1c4.5,0,7.3-0.8,9.6-1.7l1.2,4.5c-2.2,1-6.1,2.2-11.7,2.2
			c-10.7,0-17.1-7.1-17.1-17.6c0-10.6,6.3-18.9,16.4-18.9c11.4,0,14.3,10,14.3,16.4c0,1.3-0.1,2.3-0.2,3L339.2,54.3z M357.7,49.8
			c0.1-4-1.7-10.3-8.7-10.3c-6.5,0-9.2,5.9-9.7,10.3H357.7z"/>
		<path fill="#00567C" d="M401.5,65.6c0,8.1-1.7,12.9-5,16c-3.5,3.1-8.4,4.2-12.7,4.2c-4.2,0-8.8-1-11.6-2.9l1.6-4.9
			c2.3,1.5,5.9,2.8,10.2,2.8c6.5,0,11.2-3.4,11.2-12.2v-3.8h-0.1c-1.9,3.2-5.7,5.8-11.1,5.8c-8.6,0-14.8-7.3-14.8-17.1
			c0-11.8,7.7-18.4,15.8-18.4c6,0,9.3,3.1,10.9,6h0.1l0.2-5.2h5.6c-0.2,2.4-0.3,5.3-0.3,9.5V65.6z M395.2,49.5c0-1-0.1-1.9-0.4-2.8
			c-1.2-3.7-4.2-6.8-8.9-6.8c-6,0-10.4,5.1-10.4,13.2c0,6.8,3.5,12.5,10.3,12.5c4,0,7.5-2.4,8.8-6.6c0.4-1,0.5-2.2,0.5-3.4V49.5z"/>
		<path fill="#00567C" d="M441.8,65.6c0,8.1-1.7,12.9-5,16c-3.5,3.1-8.4,4.2-12.7,4.2c-4.2,0-8.8-1-11.6-2.9L414,78
			c2.3,1.5,5.9,2.8,10.2,2.8c6.5,0,11.2-3.4,11.2-12.2v-3.8h-0.1c-1.9,3.2-5.7,5.8-11.1,5.8c-8.6,0-14.8-7.3-14.8-17.1
			c0-11.8,7.7-18.4,15.8-18.4c6,0,9.3,3.1,10.9,6h0.1l0.2-5.2h5.6c-0.2,2.4-0.3,5.3-0.3,9.5V65.6z M435.4,49.5c0-1-0.1-1.9-0.4-2.8
			c-1.2-3.7-4.2-6.8-8.9-6.8c-6,0-10.4,5.1-10.4,13.2c0,6.8,3.5,12.5,10.3,12.5c4,0,7.5-2.4,8.8-6.6c0.4-1,0.5-2.2,0.5-3.4V49.5z"/>
		<path fill="#00567C" d="M455.8,54.3c0.1,8.6,5.5,12.1,11.9,12.1c4.5,0,7.3-0.8,9.6-1.7l1.2,4.5c-2.2,1-6.1,2.2-11.7,2.2
			c-10.7,0-17.1-7.1-17.1-17.6c0-10.6,6.3-18.9,16.4-18.9c11.4,0,14.3,10,14.3,16.4c0,1.3-0.1,2.3-0.2,3L455.8,54.3z M474.3,49.8
			c0.1-4-1.7-10.3-8.7-10.3c-6.5,0-9.2,5.9-9.7,10.3H474.3z"/>
		<path fill="#00567C" d="M488.3,45.3c0-3.7-0.1-6.6-0.3-9.4h5.6l0.4,5.7h0.1c1.7-3.2,5.8-6.5,11.5-6.5c4.8,0,12.3,2.9,12.3,14.8
			v20.8h-6.3V50.6c0-5.6-2.1-10.4-8.1-10.4c-4.1,0-7.3,3-8.5,6.5c-0.3,0.8-0.4,1.9-0.4,3v21h-6.3V45.3z"/>
		<path fill="#00567C" d="M568,62.3c0,3,0.1,6,0.5,8.4h-5.7l-0.6-4.4h-0.2c-1.9,2.7-5.7,5.2-10.7,5.2c-7.1,0-10.7-5-10.7-10
			c0-8.4,7.5-13,21-13v-0.7c0-2.9-0.8-8.1-7.9-8.1c-3.3,0-6.7,0.9-9.1,2.6l-1.4-4.2c2.9-1.8,7.1-3,11.5-3c10.7,0,13.3,7.3,13.3,14.3
			V62.3z M561.9,52.9c-6.9-0.1-14.8,1.1-14.8,7.8c0,4.2,2.7,6,5.9,6c4.6,0,7.6-2.9,8.6-5.8c0.2-0.6,0.3-1.4,0.3-2V52.9z"/>
		<path fill="#00567C" d="M577.3,64.3c1.9,1.2,5.3,2.4,8.4,2.4c4.5,0,6.7-2.2,6.7-5.2c0-3-1.8-4.7-6.4-6.4c-6.3-2.3-9.3-5.7-9.3-9.9
			c0-5.6,4.6-10.2,12-10.2c3.5,0,6.6,0.9,8.5,2.2l-1.5,4.5c-1.4-0.8-3.9-2-7.1-2c-3.7,0-5.8,2.2-5.8,4.8c0,3,2,4.2,6.6,6
			c6,2.2,9.1,5.3,9.1,10.5c0,6.2-4.8,10.5-13,10.5c-3.8,0-7.3-1-9.8-2.4L577.3,64.3z"/>
	</g>
	<g class="logo-subtitle">
		<path fill="#56778E" d="M170.7,97.3c-0.4,0.6-0.9,1.1-1.6,1.5l6.5,19.3h-2.8l-1.8-5.6h-7.7l-1.8,5.6h-2.7l6.5-19.3
			c-0.7-0.4-1.2-0.9-1.6-1.5c-0.4-0.6-0.6-1.3-0.6-2.1c0-1.2,0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,2.9-1.2c1.2,0,2.2,0.4,3,1.2
			c0.8,0.8,1.2,1.8,1.2,2.9C171.3,96,171.1,96.7,170.7,97.3z M170.2,110.2l-3.1-9.5l-3.1,9.5H170.2z M169.3,95.3
			c0-0.7-0.2-1.2-0.6-1.6c-0.4-0.4-0.9-0.7-1.6-0.7c-0.6,0-1.1,0.2-1.5,0.7c-0.4,0.4-0.6,1-0.6,1.6c0,0.7,0.2,1.2,0.6,1.7
			c0.4,0.4,0.9,0.7,1.5,0.7c0.6,0,1.1-0.2,1.5-0.7C169.1,96.5,169.3,95.9,169.3,95.3z"/>
		<path fill="#56778E" d="M192,118.1h-2.5v-8.6c0-0.7,0-1.3-0.1-1.9c-0.1-0.6-0.2-1.1-0.4-1.4c-0.2-0.4-0.5-0.7-0.9-0.8
			c-0.4-0.2-0.9-0.3-1.5-0.3c-0.6,0-1.3,0.2-2,0.5c-0.7,0.3-1.3,0.8-2,1.3v11.3h-2.5V103h2.5v1.7c0.8-0.7,1.5-1.2,2.3-1.6
			c0.7-0.4,1.5-0.5,2.3-0.5c1.5,0,2.7,0.5,3.5,1.5c0.8,1,1.3,2.4,1.3,4.3V118.1z"/>
		<path fill="#56778E" d="M210,118.1h-2.5v-1.5c-0.4,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.6,0.4-1,0.6c-0.4,0.2-0.7,0.3-1.1,0.4
			c-0.4,0.1-0.8,0.1-1.4,0.1c-0.8,0-1.6-0.2-2.3-0.5c-0.7-0.3-1.3-0.8-1.8-1.5c-0.5-0.7-0.9-1.5-1.2-2.5c-0.3-1-0.4-2.1-0.4-3.4
			c0-1.3,0.2-2.5,0.5-3.5c0.3-1,0.8-1.8,1.4-2.5c0.6-0.7,1.2-1.2,2-1.6c0.8-0.4,1.6-0.6,2.5-0.6c0.8,0,1.4,0.1,2,0.2
			c0.6,0.2,1.2,0.4,1.8,0.8V97h2.5V118.1z M207.4,114.4v-8.7c-0.6-0.3-1.2-0.5-1.7-0.6c-0.5-0.1-1-0.2-1.6-0.2
			c-1.3,0-2.3,0.5-3.1,1.5c-0.7,1-1.1,2.4-1.1,4.3c0,1.8,0.3,3.1,0.9,4.1c0.6,0.9,1.5,1.4,2.7,1.4c0.7,0,1.3-0.2,2-0.5
			C206.2,115.3,206.8,114.9,207.4,114.4z"/>
		<path fill="#56778E" d="M224.9,116.5c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.3-0.7,0.5-1,0.7c-0.5,0.2-0.9,0.4-1.3,0.5
			c-0.4,0.1-1,0.2-1.8,0.2c-0.6,0-1.2-0.1-1.8-0.4c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.3-0.4-1.9
			c0-1,0.2-1.9,0.7-2.7c0.4-0.7,1.1-1.3,2.1-1.8c0.9-0.4,1.9-0.7,3-0.8c1.2-0.1,2.4-0.3,3.7-0.3v-0.5c0-0.6-0.1-1.1-0.3-1.4
			c-0.2-0.4-0.4-0.7-0.8-0.8c-0.3-0.2-0.7-0.3-1.2-0.4c-0.5-0.1-1-0.1-1.5-0.1c-0.6,0-1.3,0.1-2.1,0.3c-0.8,0.2-1.6,0.4-2.4,0.8
			h-0.1v-2.6c0.5-0.1,1.1-0.3,2-0.4c0.9-0.2,1.7-0.2,2.6-0.2c1,0,1.9,0.1,2.6,0.2c0.7,0.2,1.4,0.5,1.9,0.9c0.6,0.4,1,1,1.2,1.6
			c0.3,0.7,0.4,1.5,0.4,2.4v10.4h-2.5V116.5z M224.9,114.4v-4.2c-0.6,0.1-1.4,0.1-2.3,0.2c-0.9,0.1-1.7,0.2-2.2,0.4
			c-0.7,0.2-1.2,0.5-1.7,1c-0.4,0.4-0.7,1-0.7,1.8c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.2,0.7,2.1,0.7c0.8,0,1.5-0.2,2.2-0.5
			C223.7,115.3,224.3,114.9,224.9,114.4z"/>
		<path fill="#56778E" d="M236.4,118.1h-2.5V97h2.5V118.1z"/>
		<path fill="#56778E" d="M247,118.5c-1,0-2-0.1-2.8-0.4c-0.9-0.3-1.6-0.6-2.2-0.9v-2.9h0.1c0.2,0.2,0.5,0.4,0.8,0.6
			c0.3,0.2,0.7,0.4,1.2,0.7c0.4,0.2,0.9,0.4,1.4,0.5c0.5,0.2,1.1,0.2,1.6,0.2c0.4,0,0.8,0,1.3-0.1c0.5-0.1,0.8-0.2,1-0.4
			c0.3-0.2,0.5-0.4,0.7-0.7c0.1-0.2,0.2-0.6,0.2-1.1c0-0.6-0.2-1.1-0.5-1.4c-0.3-0.3-0.9-0.6-1.7-0.8c-0.3-0.1-0.8-0.2-1.3-0.3
			c-0.5-0.1-1-0.2-1.5-0.3c-1.2-0.3-2-0.9-2.5-1.6c-0.5-0.7-0.8-1.5-0.8-2.5c0-1.4,0.5-2.5,1.5-3.3c1-0.9,2.4-1.3,4.1-1.3
			c0.8,0,1.7,0.1,2.5,0.3c0.8,0.2,1.6,0.5,2.1,0.8v2.7h-0.1c-0.7-0.5-1.4-0.9-2.2-1.2c-0.8-0.3-1.6-0.5-2.4-0.5
			c-0.8,0-1.5,0.2-2.1,0.5c-0.6,0.4-0.9,0.9-0.9,1.6c0,0.6,0.2,1.1,0.5,1.4c0.3,0.3,0.9,0.6,1.6,0.8c0.4,0.1,0.8,0.2,1.3,0.3
			c0.5,0.1,1,0.2,1.5,0.3c1.1,0.3,2,0.7,2.5,1.4c0.6,0.7,0.8,1.5,0.8,2.7c0,0.7-0.1,1.3-0.4,1.8c-0.3,0.6-0.6,1.1-1.1,1.5
			c-0.5,0.5-1.2,0.8-1.8,1C248.9,118.3,248.1,118.5,247,118.5z"/>
		<path fill="#56778E" d="M270,118.1h-2.5v-8.6c0-0.7,0-1.3-0.1-1.9c-0.1-0.6-0.2-1.1-0.4-1.4c-0.2-0.4-0.5-0.7-0.9-0.8
			c-0.4-0.2-0.9-0.3-1.5-0.3c-0.6,0-1.3,0.2-2,0.5c-0.7,0.3-1.3,0.8-2,1.3v11.3h-2.5V103h2.5v1.7c0.8-0.7,1.5-1.2,2.3-1.6
			c0.7-0.4,1.5-0.5,2.4-0.5c1.5,0,2.7,0.5,3.5,1.5c0.8,1,1.3,2.4,1.3,4.3V118.1z"/>
		<path fill="#56778E" d="M282.9,118.4c-2.4,0-4.3-0.7-5.6-2c-1.3-1.4-2-3.3-2-5.8c0-2.5,0.6-4.4,1.9-5.9c1.3-1.5,3-2.2,5.1-2.2
			c0.9,0,1.8,0.1,2.5,0.4c0.7,0.3,1.4,0.7,1.9,1.3c0.5,0.6,1,1.3,1.2,2.1c0.3,0.8,0.4,1.9,0.4,3.1v1.4h-10.5c0,1.8,0.4,3.1,1.3,4
			c0.9,0.9,2.1,1.4,3.7,1.4c0.6,0,1.1-0.1,1.6-0.2c0.5-0.1,1-0.3,1.5-0.5c0.5-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.6-0.4,0.8-0.6h0.1v2.8
			c-0.3,0.1-0.7,0.3-1.1,0.4c-0.4,0.2-0.8,0.3-1.2,0.4c-0.5,0.1-0.9,0.2-1.3,0.3C284.1,118.4,283.5,118.4,282.9,118.4z M285.9,108.8
			c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.3c-0.3-0.4-0.7-0.7-1.1-0.9c-0.5-0.2-1-0.3-1.7-0.3c-0.7,0-1.3,0.1-1.7,0.3
			c-0.5,0.2-0.9,0.5-1.3,0.9c-0.4,0.4-0.7,0.8-0.9,1.3c-0.2,0.5-0.3,1-0.4,1.7H285.9z"/>
		<path fill="#56778E" d="M297.8,118.5c-1,0-2-0.1-2.8-0.4c-0.9-0.3-1.6-0.6-2.2-0.9v-2.9h0.1c0.2,0.2,0.5,0.4,0.8,0.6
			c0.3,0.2,0.7,0.4,1.2,0.7c0.4,0.2,0.9,0.4,1.4,0.5c0.5,0.2,1.1,0.2,1.6,0.2c0.4,0,0.8,0,1.3-0.1c0.5-0.1,0.8-0.2,1-0.4
			c0.3-0.2,0.5-0.4,0.7-0.7c0.1-0.2,0.2-0.6,0.2-1.1c0-0.6-0.2-1.1-0.5-1.4c-0.3-0.3-0.9-0.6-1.7-0.8c-0.3-0.1-0.8-0.2-1.3-0.3
			c-0.5-0.1-1-0.2-1.5-0.3c-1.2-0.3-2-0.9-2.5-1.6c-0.5-0.7-0.8-1.5-0.8-2.5c0-1.4,0.5-2.5,1.5-3.3c1-0.9,2.4-1.3,4.1-1.3
			c0.8,0,1.7,0.1,2.5,0.3c0.9,0.2,1.6,0.5,2.1,0.8v2.7h-0.1c-0.6-0.5-1.4-0.9-2.2-1.2c-0.8-0.3-1.6-0.5-2.4-0.5
			c-0.8,0-1.5,0.2-2.1,0.5c-0.6,0.4-0.9,0.9-0.9,1.6c0,0.6,0.2,1.1,0.5,1.4c0.3,0.3,0.9,0.6,1.6,0.8c0.4,0.1,0.8,0.2,1.3,0.3
			c0.5,0.1,1,0.2,1.5,0.3c1.1,0.3,2,0.7,2.5,1.4c0.6,0.7,0.8,1.5,0.8,2.7c0,0.7-0.1,1.3-0.4,1.8c-0.3,0.6-0.6,1.1-1.1,1.5
			c-0.5,0.5-1.2,0.8-1.8,1C299.7,118.3,298.9,118.5,297.8,118.5z"/>
		<path fill="#56778E" d="M332.8,110.5c0,2.5-0.6,4.5-1.8,5.9c-1.2,1.4-2.9,2.1-4.9,2.1c-2.1,0-3.8-0.7-5-2.2
			c-1.2-1.4-1.8-3.4-1.8-5.8c0-2.5,0.6-4.5,1.9-5.9c1.2-1.4,2.9-2.1,4.9-2.1c2,0,3.7,0.7,4.9,2.1C332.2,106,332.8,108,332.8,110.5z
			 M330.2,110.5c0-2-0.4-3.4-1.1-4.4c-0.7-1-1.7-1.4-3.1-1.4c-1.3,0-2.4,0.5-3.1,1.4c-0.7,1-1.1,2.4-1.1,4.4c0,1.9,0.4,3.3,1.1,4.3
			c0.7,1,1.7,1.5,3.1,1.5c1.3,0,2.3-0.5,3-1.4C329.8,113.9,330.2,112.5,330.2,110.5z"/>
		<path fill="#56778E" d="M338.8,120.5c0.2,0.1,0.4,0.2,0.8,0.3c0.3,0.1,0.7,0.2,1.1,0.3c0.5,0.1,0.9,0.2,1.3,0.3
			c0.4,0.1,0.8,0.1,1.3,0.1c0.8,0,1.5-0.1,2-0.3c0.5-0.2,1-0.5,1.3-0.9c0.3-0.4,0.5-0.8,0.6-1.3c0.1-0.5,0.2-1,0.2-1.7v-1.3
			c-0.7,0.6-1.4,1-2,1.3c-0.7,0.3-1.5,0.4-2.5,0.4c-1.7,0-3.1-0.6-4.1-1.9c-1-1.3-1.5-3.1-1.5-5.5c0-1.3,0.2-2.4,0.5-3.3
			c0.4-1,0.8-1.8,1.4-2.5c0.6-0.7,1.2-1.2,2-1.5c0.8-0.4,1.6-0.5,2.4-0.5c0.8,0,1.5,0.1,2,0.3c0.6,0.2,1.1,0.4,1.7,0.8l0.2-0.6h2.4
			v13.4c0,2.6-0.6,4.4-1.7,5.6c-1.1,1.2-2.8,1.8-5.1,1.8c-0.7,0-1.5-0.1-2.3-0.2c-0.8-0.1-1.5-0.3-2.2-0.5v-2.7H338.8z M347.3,114
			v-8.3c-0.6-0.3-1.2-0.5-1.8-0.7c-0.5-0.1-1.1-0.2-1.6-0.2c-1.3,0-2.3,0.5-3.1,1.4c-0.7,0.9-1.1,2.3-1.1,4.1c0,1.7,0.3,3,0.8,3.8
			c0.6,0.9,1.5,1.3,2.7,1.3c0.7,0,1.3-0.1,2-0.4C346.1,114.8,346.8,114.5,347.3,114z"/>
		<path fill="#56778E" d="M383.6,118.1h-3.5l-6.4-8h-3.1v8H368V97.9h5.2c1.2,0,2.1,0.1,2.9,0.2c0.8,0.1,1.5,0.4,2.2,0.9
			c0.7,0.5,1.3,1,1.7,1.7c0.4,0.7,0.6,1.6,0.6,2.7c0,1.5-0.4,2.7-1.1,3.7c-0.7,1-1.8,1.7-3.1,2.2L383.6,118.1z M377.8,103.6
			c0-0.6-0.1-1.1-0.3-1.6c-0.2-0.5-0.5-0.8-1-1.1c-0.4-0.3-0.8-0.4-1.3-0.5c-0.5-0.1-1.1-0.2-1.9-0.2h-2.7v7.6h2.4
			c0.8,0,1.5-0.1,2.1-0.2c0.6-0.1,1.1-0.4,1.6-0.8c0.4-0.4,0.7-0.8,0.9-1.4C377.7,104.9,377.8,104.3,377.8,103.6z"/>
		<path fill="#56778E" d="M396,116.5c-0.2,0.2-0.5,0.4-0.8,0.6c-0.4,0.3-0.7,0.5-1,0.7c-0.5,0.2-0.9,0.4-1.3,0.5
			c-0.4,0.1-1,0.2-1.8,0.2c-0.6,0-1.2-0.1-1.8-0.4c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.3-0.4-1.9
			c0-1,0.2-1.9,0.7-2.7c0.4-0.7,1.1-1.3,2.1-1.8c0.9-0.4,1.9-0.7,3-0.8c1.2-0.1,2.4-0.3,3.7-0.3v-0.5c0-0.6-0.1-1.1-0.3-1.4
			c-0.2-0.4-0.4-0.7-0.8-0.8c-0.3-0.2-0.7-0.3-1.2-0.4c-0.5-0.1-1-0.1-1.5-0.1c-0.6,0-1.3,0.1-2.1,0.3c-0.8,0.2-1.6,0.4-2.4,0.8
			h-0.1v-2.6c0.5-0.1,1.1-0.3,2-0.4c0.9-0.2,1.7-0.2,2.6-0.2c1,0,1.9,0.1,2.6,0.2c0.7,0.2,1.4,0.5,1.9,0.9c0.6,0.4,1,1,1.2,1.6
			c0.3,0.7,0.4,1.5,0.4,2.4v10.4H396V116.5z M396,114.4v-4.2c-0.6,0.1-1.4,0.1-2.3,0.2c-0.9,0.1-1.7,0.2-2.2,0.4
			c-0.7,0.2-1.2,0.5-1.7,1c-0.4,0.4-0.7,1-0.7,1.8c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.2,0.7,2.1,0.7c0.8,0,1.5-0.2,2.2-0.5
			C394.8,115.3,395.4,114.9,396,114.4z"/>
		<path fill="#56778E" d="M416.7,118.1h-2.5v-1.7c-0.8,0.7-1.5,1.2-2.2,1.6c-0.7,0.4-1.5,0.5-2.4,0.5c-0.7,0-1.3-0.1-1.9-0.3
			c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.5-0.8-1.1-1-1.8c-0.2-0.7-0.4-1.6-0.4-2.6V103h2.5v8.6c0,0.8,0,1.5,0.1,2c0.1,0.5,0.2,1,0.4,1.4
			c0.2,0.4,0.5,0.7,0.8,0.9c0.4,0.2,0.9,0.3,1.5,0.3c0.6,0,1.3-0.2,2-0.5c0.7-0.4,1.4-0.8,1.9-1.3V103h2.5V118.1z"/>
		<path fill="#56778E" d="M440.4,118.1v-8.8c0-0.7,0-1.3-0.1-1.9c-0.1-0.6-0.2-1.1-0.3-1.4c-0.2-0.4-0.4-0.6-0.8-0.8
			c-0.3-0.2-0.8-0.3-1.4-0.3c-0.5,0-1.1,0.2-1.7,0.5c-0.6,0.3-1.2,0.8-1.9,1.4c0,0.2,0,0.3,0,0.5s0,0.5,0,0.8v10h-2.5v-8.8
			c0-0.7,0-1.3-0.1-1.9c-0.1-0.6-0.2-1.1-0.3-1.4c-0.2-0.4-0.4-0.6-0.8-0.8c-0.3-0.2-0.8-0.3-1.4-0.3c-0.6,0-1.1,0.2-1.7,0.5
			c-0.6,0.3-1.2,0.8-1.8,1.3v11.3h-2.5V103h2.5v1.7c0.7-0.7,1.3-1.2,2-1.6c0.7-0.4,1.4-0.5,2.2-0.5c0.9,0,1.7,0.2,2.4,0.6
			c0.7,0.4,1.2,1.1,1.6,2c0.8-0.9,1.5-1.5,2.3-2c0.7-0.4,1.5-0.7,2.4-0.7c0.7,0,1.2,0.1,1.8,0.3c0.5,0.2,1,0.5,1.4,1
			c0.4,0.5,0.7,1,0.9,1.7c0.2,0.7,0.3,1.6,0.3,2.6v10H440.4z"/>
		<path fill="#56778E" d="M457.7,116.5c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.3-0.7,0.5-1,0.7c-0.5,0.2-0.9,0.4-1.3,0.5
			c-0.4,0.1-1,0.2-1.8,0.2c-0.6,0-1.2-0.1-1.8-0.4c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.3-0.4-1.9
			c0-1,0.2-1.9,0.7-2.7c0.4-0.7,1.1-1.3,2.1-1.8c0.8-0.4,1.9-0.7,3-0.8c1.2-0.1,2.4-0.3,3.7-0.3v-0.5c0-0.6-0.1-1.1-0.3-1.4
			c-0.2-0.4-0.4-0.7-0.8-0.8c-0.3-0.2-0.7-0.3-1.2-0.4c-0.5-0.1-1-0.1-1.5-0.1c-0.6,0-1.3,0.1-2.1,0.3c-0.8,0.2-1.6,0.4-2.4,0.8
			h-0.1v-2.6c0.5-0.1,1.1-0.3,2-0.4c0.9-0.2,1.7-0.2,2.6-0.2c1,0,1.9,0.1,2.6,0.2c0.7,0.2,1.4,0.5,1.9,0.9c0.6,0.4,1,1,1.2,1.6
			c0.3,0.7,0.4,1.5,0.4,2.4v10.4h-2.5V116.5z M457.7,114.4v-4.2c-0.6,0.1-1.4,0.1-2.3,0.2c-0.9,0.1-1.7,0.2-2.2,0.4
			c-0.7,0.2-1.2,0.5-1.7,1c-0.4,0.4-0.7,1-0.7,1.8c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.2,0.7,2.1,0.7c0.8,0,1.5-0.2,2.2-0.5
			C456.5,115.3,457.2,114.9,457.7,114.4z"/>
		<path fill="#56778E" d="M489.9,118.1h-2.5v-1.7c-0.8,0.7-1.5,1.2-2.2,1.6c-0.7,0.4-1.5,0.5-2.4,0.5c-0.7,0-1.3-0.1-1.9-0.3
			c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.5-0.8-1.1-1-1.8c-0.2-0.7-0.4-1.6-0.4-2.6V103h2.5v8.6c0,0.8,0,1.5,0.1,2c0.1,0.5,0.2,1,0.4,1.4
			c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.9,0.3,1.5,0.3c0.6,0,1.3-0.2,2-0.5c0.7-0.4,1.4-0.8,1.9-1.3V103h2.5V118.1z"/>
		<path fill="#56778E" d="M500.9,118.4c-1.4,0-2.5-0.4-3.2-1.2c-0.8-0.8-1.2-2-1.2-3.7v-8.4h-1.7V103h1.7v-4.4h2.5v4.4h4.7v2.1h-4.7
			v7.2c0,0.8,0,1.4,0,1.8c0,0.4,0.1,0.8,0.3,1.2c0.2,0.3,0.4,0.6,0.7,0.7c0.3,0.2,0.8,0.2,1.4,0.2c0.4,0,0.8-0.1,1.2-0.2
			c0.4-0.1,0.7-0.2,0.8-0.3h0.1v2.3c-0.5,0.1-1,0.2-1.5,0.3C501.8,118.4,501.3,118.4,500.9,118.4z"/>
		<path fill="#56778E" d="M520,103l-5.7,15.1H512l-5.7-15.1h2.8l4.2,11.7l4.1-11.7H520z"/>
		<path fill="#56778E" d="M527.5,100.4h-2.9v-2.6h2.9V100.4z M527.3,118.1h-2.5V103h2.5V118.1z"/>
		<path fill="#56778E" d="M546.4,118.1h-3.3l-5.6-7.3l-1,1v6.3h-2.5V97h2.5v12.4l6-6.4h3.2l-6.2,6.3L546.4,118.1z"/>
		<path fill="#56778E" d="M553.1,118.1h-2.5V97h2.5V118.1z"/>
		<path fill="#56778E" d="M562.4,100.4h-2.9v-2.6h2.9V100.4z M562.2,118.1h-2.5V103h2.5V118.1z"/>
		<path fill="#56778E" d="M580.6,118.1h-2.5v-8.6c0-0.7,0-1.3-0.1-1.9c-0.1-0.6-0.2-1.1-0.4-1.4c-0.2-0.4-0.5-0.7-0.9-0.8
			c-0.4-0.2-0.9-0.3-1.5-0.3c-0.6,0-1.3,0.2-2,0.5c-0.7,0.3-1.3,0.8-2,1.3v11.3h-2.5V103h2.5v1.7c0.8-0.7,1.5-1.2,2.3-1.6
			c0.7-0.4,1.5-0.5,2.3-0.5c1.5,0,2.7,0.5,3.5,1.5c0.8,1,1.3,2.4,1.3,4.3V118.1z"/>
		<path fill="#56778E" d="M587.5,120.5c0.2,0.1,0.4,0.2,0.8,0.3c0.3,0.1,0.7,0.2,1.1,0.3c0.5,0.1,0.9,0.2,1.3,0.3
			c0.4,0.1,0.8,0.1,1.3,0.1c0.8,0,1.5-0.1,2-0.3c0.5-0.2,1-0.5,1.3-0.9c0.3-0.4,0.5-0.8,0.6-1.3c0.1-0.5,0.2-1,0.2-1.7v-1.3
			c-0.7,0.6-1.4,1-2,1.3c-0.7,0.3-1.5,0.4-2.5,0.4c-1.7,0-3.1-0.6-4.1-1.9c-1-1.3-1.5-3.1-1.5-5.5c0-1.3,0.2-2.4,0.5-3.3
			c0.4-1,0.8-1.8,1.4-2.5c0.6-0.7,1.2-1.2,2-1.5c0.8-0.4,1.6-0.5,2.4-0.5c0.8,0,1.5,0.1,2,0.3c0.6,0.2,1.1,0.4,1.7,0.8l0.2-0.6h2.4
			v13.4c0,2.6-0.6,4.4-1.7,5.6c-1.1,1.2-2.8,1.8-5.1,1.8c-0.7,0-1.5-0.1-2.3-0.2c-0.8-0.1-1.5-0.3-2.2-0.5v-2.7H587.5z M596.1,114
			v-8.3c-0.6-0.3-1.2-0.5-1.8-0.7c-0.5-0.1-1.1-0.2-1.6-0.2c-1.3,0-2.3,0.5-3.1,1.4c-0.7,0.9-1.1,2.3-1.1,4.1c0,1.7,0.3,3,0.8,3.8
			c0.6,0.9,1.5,1.3,2.7,1.3c0.7,0,1.3-0.1,2-0.4C594.9,114.8,595.5,114.5,596.1,114z"/>
	</g>
</g>
</svg>

  </a>

  <div class="decoration">

    <svg class="symbol-left" viewBox="-9879.557 4427.44 140.557 162.299">
      <g class="symbol-left-group" transform="translate(-9879.557 4427.44)">
        <path class="symbol-left-path" d="M0,45.69l140.556,81.148v-162.3Z" transform="translate(0 35.461)"/>
      </g>
    </svg>


    <svg class="symbol-right" viewBox="-9879.557 4427.44 140.557 162.299">
      <g class="symbol-right-group" transform="translate(-9879.557 4427.44)">
        <path class="symbol-right-path" d="M0,45.69l140.556,81.148v-162.3Z" transform="translate(0 35.461)"/>
      </g>
    </svg>

  </div>

  <div id="navigationMenu" class="navigation-wrapper">
    <div class="navigation-inner">
      <nav class="navigation-container">
        <ul>
          <li>
            <a routerLink="/" (click)="closeNavigationMenu($event)">Hjem</a>
          </li>

          <li>
            <a routerLink="/side/naeringsliv" (click)="closeNavigationMenu($event)">Næringsliv</a>
          </li>
		  <li>
		  	<a routerLink="/side/hoppid" (click)="closeNavigationMenu($event)">Etablere bedrift</a>
		  </li>
		  <li>
		  	<a routerLink="/side/tjenester" (click)="closeNavigationMenu($event)">Tjenester</a>
		  </li>
		   <li>
            <a routerLink="/side/prosjekter" (click)="closeNavigationMenu($event)">Prosjekter</a>
          </li>
          <li>
            <a routerLink="/side/jobbeogbo" (click)="closeNavigationMenu($event)">Jobbe og bo i Rauma</a>
          </li>

          <li>
            <a routerLink="/side/utrona" (click)="closeNavigationMenu($event)">Utrøna Innovasjonssenter</a>
          </li>

          <li>
            <a routerLink="/side/info" (click)="closeNavigationMenu($event)">Nyheter og rapporter</a>
          </li>

          <li>
            <a routerLink="/side/ressurser" (click)="closeNavigationMenu($event)">Ressurser</a>
          </li>

          <!--<li>-->
            <!--<a routerLink="/side/arkiv" (click)="closeNavigationMenu($event)">Arkiv</a>-->
          <!--</li>-->

          <li>
            <a routerLink="/side/omnordveggen" (click)="closeNavigationMenu($event)">Om Nordveggen</a>
          </li>
		  <li>
            <a routerLink="/kalender" (click)="closeNavigationMenu($event)">Kalender</a>
          </li>
        </ul>
        <form action="" class="navigation-search">
          <label class="search-box-label" for="search-box">Søk på siden</label>
          <input class="search-box-input" type="text" id="search-box" #searchBox (keyup.enter)="globalSearch($event,searchBox.value)">
        </form>
      </nav>
      <nav class="sub-navigation-container">
        <ul>
          <li>
            <a routerLink="/side/naeringsliv" (click)="closeNavigationMenu($event)">Næringsliv</a>
          </li>
          <li>
            <a routerLink="/side/hoppid" (click)="closeNavigationMenu($event)">Etablere bedrift</a>
          </li>
          <li>
            <a routerLink="/kaskjer" (click)="closeNavigationMenu($event)">Ka skjer?</a>
          </li>
          <li>
            <a routerLink="/side/jobbirauma" (click)="closeNavigationMenu($event)">Ledige stillinger</a>
          </li>

        </ul>
      </nav>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<footer class="main-footer">

  <div class="row rauma">
    <img src="web/Profil-2.svg" class="small-12 columns">
  </div>
  <div class="footer-container">
    <div class="footer-column footer-phone">
      <div class="footer-column-content">
        <div class="footer-column-icon icon-screen-smartphone"></div>
        <div class="footer-column-text">71 22 19 41</div>
      </div>
    </div>
    <div class="footer-column footer-address">
      <div class="footer-column-content">
        <div class="footer-column-icon simple icon-location-pin"></div>
        <div class="footer-column-text">Utrøna Innovasjonssenter, Strandvegen 106, 6300 Åndalsnes</div>
      </div>
    </div>
    <div class="footer-column footer-email">
      <div class="footer-column-content">
        <div class="footer-column-icon simple icon-envelope"></div>
        <div class="footer-column-text"><a href="mailto:post@nordveggen.no">post@nordveggen.no</a></div>
      </div>
    </div>
  </div>

</footer>
