<div *ngIf="page && page.ImageData && page.ImageData.length>0" class="page-nub" page-nub></div>
<app-cards *ngIf="isLoaded && jobOpenings" [page]="page" [cards]="jobOpenings" [blur]="false">

</app-cards>

<app-page *ngIf="isLoaded && !jobOpenings" [page]="page">

</app-page>

<!-- <app-common-header *ngIf="page" [blur]="true" [title]="page.subject" [description]="page.Resume"
    [imageData]="page.ImageData">
</app-common-header>

<app-shortcuts [isPage]="true" [isCollapsed]="true"></app-shortcuts>

<div class="page-container page">
    <div class="page-container-wrapper">
        <main class="page-container-page">            
            <div *ngIf="page.body||page.Body" [innerHTML]="page.body||page.Body|sanitizeHtml"></div>
            <p></p>
            <div class="cards-container cards-container-maesonry">
                <article app-card *ngFor="let job of jobOpenings" [card]="job"></article>
            </div>
        </main>
    </div>
</div>
 -->
  