import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NewsService } from '../news-entry/news.service';
import { Card } from '../card/card';
import { Router } from '@angular/router';

export const arkivCard = [
  {subject: 'Arkiv Naturgledeposten',urlId: 'arkiv/naturgledeposten'},
  {subject: 'Arkiv Nytt fra Levende Lesja',urlId: 'arkiv/levendelesja'}
]
@Component({
  selector: 'app-optin',
  templateUrl: './optin.component.html',
  styleUrls: ['./optin.component.css']
})

export class OptinComponent implements OnInit {

  formGroup: FormGroup;
  @Input() mailListName = '1200 Naturgledeposten epost';
  @Input() compDir = 'nordveggen';
  @Input() companyName = 'Nordveggen AS';
  @Input() title = 'Få med deg hva som skjer';
  @Input() subscribeButtonTitle = 'Meld meg på Naturgledeposten';
  subscribed = false;
  isLoading: boolean = false;
  allPosts: Array<Card> = [];
  isValid: boolean = false;

  constructor(private http: HttpClient,private newsService:NewsService,private router:Router) {
    this.formGroup = new FormGroup({
      'internetAddress': new FormControl('', [Validators.required, Validators.email]),
      'consent': new FormControl(false, [Validators.required]),
      'naturgledePosten': new FormControl(false),
      'levendeLesja':new FormControl(false)
    });
    this.onChanges();
  }
  onSubmit(schema) {
    this.createProfile(schema);
  }
  ngOnInit() {
    this.allPosts = [];
    arkivCard.forEach(data => {
      var newsCard:Card = new Card(data.subject,"","","",null);
      newsCard.pageLink= data.urlId;
      this.allPosts.push(newsCard);
    })
  }
  onChanges():void {
    this.formGroup.valueChanges.subscribe(val => {
      if(val.naturgledePosten || val.levendeLesja) {
        this.isValid = true;
      }else {this.isValid = false;}
    })
  }
  private createProfile(schema) {
    const postData = Object.assign({}, schema);
    postData.mailList = [this.mailListName];
    postData.compDir = this.compDir;
    postData.companyName = this.companyName
    postData.newslatterDisplayName = 'Nyhetsbrev';
    postData.firstName = '';
    postData.lastName = '';
    
    const url = 'https://api.ibricks.no/ibricks/Optout.nsf/Rest.xsp/profile/optin';
    this.http.post(url, postData).subscribe(res => {
      this.formGroup.reset();
      this.subscribed = true;
      console.log(res);
    }, error => {

    });
  }
  get email() { return this.formGroup.get('internetAddress'); }

}
