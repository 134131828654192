
<app-page *ngIf="page" [page]="page" [class]="'design'">
  <div class="page-extra-after-body design">
    <div *ngFor="let att of attachments">
      <div class="row">
        <div class="small-4 columns">
          <img *ngIf="att.filename.match('.png$')" [src]="environment.vedlegg+'/'+att.unid+'/$File/'+att.filename" alt="{{att.subject}}">
        </div>
        <div class="small-8 columns" style="text-align: center;">
          <a [href]="environment.vedlegg+'/'+att.unid+'/$File/'+att.filename" target="_blank">{{att.subject}}</a>
        </div>
      </div>
      <hr *ngIf="att.filename.match('.png$')">
    </div>
  </div>
</app-page>

