<app-common-header
  *ngIf="page && page.ImageData && page.ImageData.length>0"
  [blur]="blur"
  [title]="page.subject"
  [description]="page.Resume"
  [imageData]="page.ImageData">
</app-common-header>


<app-shortcuts *ngIf="useShortcuts" [isPage]="true" [isCollapsed]="true"></app-shortcuts>

<div class="page-container" [class.no-image]="noImage">
  <div class="page-container-wrapper">
    <main class="page-container-wide">
      <div *ngIf="page && (!page.ImageData || page.ImageData.length<=0)" class="page-container-page">
        <h2>{{page.subject}}</h2>
        <p>{{page.Resume}}</p>
      </div>
      <div *ngIf="page" [innerHTML]="page.body"></div>
      <div *ngIf="page" [innerHTML]="page.Body"></div>
      <div *ngIf="!useCustomOrdering" class="cards-container cards-container-maesonry">
        <article app-card *ngFor="let card of cards" [card]="card" ></article>
      </div>
      <div *ngIf="useCustomOrdering" class="row cards-container">
        <article app-card *ngFor="let card of cards" [card]="card" class="small-12 medium-6 columns"></article>
      </div>
    </main>
  </div>
</div>
