import { Component, OnInit } from '@angular/core';
import {PageService} from "../page/page.service";
import {Page} from "../page/page";
import {AttachmentService} from "../attachment.service";
import {Attachment} from "../attachment";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-design-manual',
  templateUrl: './design-manual.component.html',
  styleUrls: ['./design-manual.component.css']
})
export class DesignManualComponent implements OnInit{

  page:Page = null;
  environment = environment;
  URL_ID = "raumaprofil";
  attachments:Array<Attachment> = [];
  constructor(private pageService:PageService,private attachmentService:AttachmentService) {

  }

  ngOnInit() {
    this.pageService.getPage(this.URL_ID,"").subscribe((page:Page)=>{
      this.page=page;
    });
    this.attachmentService.getAttachmentsByTag("raumaprofil").subscribe((attachments)=>{
      attachments.forEach(att=>{
        att.filename=att.filename||att.fileName||"";
      });
      this.attachments=attachments;
    })
  }

}
