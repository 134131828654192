import {ImgData} from "../img-data";
export class NewsEntry {
  ImageData?:Array<ImgData>;
  imageData?:Array<ImgData>;
  url:string;
  Subject:string;
  subject?:string;
  Created:number;
  created?:number;
  unid:string;
  urlId:string;
  Resume?:string;
  resume?:string;
  Body?:string;
  body?:string;
  linkUrl?:string;
  showDate?:number;
}
