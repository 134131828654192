import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {PageService} from "../page/page.service";
import {Page} from "../page/page";
import {Card} from "../card/card";
import {GoogleService} from "../google.service";
import {Subscription} from "rxjs/Subscription";
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.css']
})
export class PageWrapperComponent implements OnInit, OnDestroy{

  page:Page;
  urlId:string;
  cards:Array<Card>;
  isLoaded:boolean=false;
  url:string;
  routerSubscription:Subscription;
  constructor(public activatedRoute:ActivatedRoute, public pageService:PageService,
              public router:Router, public meta: Meta) {
    this.urlId = this.activatedRoute.snapshot.params["urlId"];
    this.updateNavigationSettings(router);
    this.routerSubscription = this.router.events.subscribe((val)=>{
      if(val instanceof NavigationEnd){
        this.updateNavigationSettings(router);
        this.getPage();
      }
    });
  }

  updateNavigationSettings(router:Router){
    this.url = router.url;
    if(this.url.match("^/side")){
      this.urlId= this.activatedRoute.snapshot.params["urlId"];
    }
  }

  ngOnInit() {
  }

  getPage(){
    this.cards=null;
    this.page=null;
    this.isLoaded = false;
    this.pageService.getPage(this.urlId,"").subscribe(page=>{
      this.page = page;
      this.hasUndercategories();
      this.addMeta();
    });
  }

  private hasUndercategories():void{
    if(this.page) {
      if(this.cards && this.cards.length>=0){
        this.isLoaded = true;
      }else{
        this.pageService.getPagesByTag(this.urlId).subscribe(cards=>{
          this.cards=[];
          if(cards && cards.length>0){
            for(var i = 0; i<cards.length; i++){
              console.log(cards[i].urlId);
              if(cards[i].urlId && cards[i].urlId.includes("optin")) { cards[i].pageLink = "/"+cards[i].urlId }
              else { cards[i].pageLink = "/side/"+cards[i].urlId };
              this.cards.push(cards[i]);
            }
          }else{
            this.cards = null;
          }
          this.isLoaded=true;
        },()=>{
          this.isLoaded=true;
        });
      }
    }
  }
  ngOnDestroy(){
    if(this.routerSubscription){
      this.routerSubscription.unsubscribe();
    }
  }

  addMeta() {        
    if (this.page) {
      console.log('add meta', this.page);
      var imageData = this.page.ImageData || this.page.images;
      const imgUrl = 'https://www.nordveggen.no/'+imageData[0].src;
      
      this.meta.updateTag({ 'property': 'og:image', 'content':  imgUrl});

      this.meta.updateTag({ 'property': 'og:title', 'content' : this.page.subject });
      this.meta.updateTag({ 'property': 'og:description', 'content' : this.page.resume });
    }
  }

}
