<div class="card-inner">
  <div class="card-content" (click)="routeTo(card)" [class.use-pointer]="card.externalUrl||card.pageLink">
    <p *ngIf="card.extraHeader">{{card.extraHeader}}</p>
    <h2>{{card.Subject||card.subject}}</h2>
    <p>{{card.Resume||card.resume}}</p>
    <div *ngIf="card.showDate||card.created" class="entry-date">
      Publisert {{card.showDate || card.created | date:"dd MMM yyyy"}}
    </div>
  </div>
  <div class="card-container">
    <a *ngIf="card && card.pageLink && !hasArkiv" [routerLink]="'/'+card.pageLink" [title]="card.Subject||card.subject">Les mer &rarr;</a>
    <a *ngIf="card && card.pageLink && hasArkiv" [routerLink]="'/'+card.pageLink" [title]="card.Subject||card.subject">Vis arkiv &rarr;</a>
    <a *ngIf="card && card.externalUrl" [href]="card.externalUrl" [title]="card.Subject||card.subject" target="_blank">Til eget nettsted  &rarr;</a>
  </div>
</div>
